.taskboard-hero-section-background {
  background: url("../../../../assets/taskboardPageBackgroundImage.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.hero-section-heading-wrapper {
  margin: 3rem 0rem;
}
.taskBoard-hero-section-heading {
  color: white;
  text-align: center;
  font-size: 2rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 2rem !important;
}

@media (min-width: 1024px) {
  .hero-section-heading-wrapper {
    margin: 7rem 0rem;
  }
  .taskBoard-hero-section-heading {
    font-size: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
}
