html{
    width: 100%;
    height: 100%;
 }
 
 section.heroSoon{
     margin: 3rem 0;
 }
 
 
 .headingtext {
     font-family: 'Poppins', sans-serif;
     font-style: bold;
     font-size: 2.2rem !important;
     line-height: 60px;
     letter-spacing: 0.01em;
     color: #05174B;
     font-weight: 700;
     padding-bottom: 5px;
 }
 
 
 .headingsubtext{
 font-family: 'Poppins', sans-serif;
 font-style: bold;
 font-weight: 700;
 font-size: 2rem !important;
 line-height: 60px;
 letter-spacing: 0.01em;
 color: #05174B;
 }
 
 .paraText {
     /* font-family: 'Poppins', sans-serif; */
     font-style: bold;
     font-weight:500;
     font-size: 1rem !important;
     line-height: 26px;
     letter-spacing: 0.02em;
     color: #000000;
     margin-top: 60px;
 }
 
 .icon_links
 {
     margin: 10px 20px;
 }
 
 @media screen and (max-device-width: 767px)
 {
     .headingsubtext,
     .headingtext,
     .paraText
     {
         text-align: center;
         margin: 10px auto;
     }
     .headingtext {
         font-style: normal;
         font-size: 1.6rem;
         line-height: 40px;
         letter-spacing: 0.01em;
     }
     .paraText{
         margin-top:40px;
     }
 
     .headingsubtext{
         font-style: normal;
         font-weight: 700;
         font-size: 1.5rem;
         line-height: 40px;
         letter-spacing: 0.01em;
         color: #05174B;
         }
 }
 
 @media screen and (min-device-width: 768px)
 and (max-device-width: 1024px) {
     .headingtext {
         font-style: normal;
         font-size: 1.5rem;
         line-height: 40px;
         letter-spacing: 0.03em;
     }
     .paraText{
         margin-top:40px;
         font-size: 0.9rem;
     }
 
     .headingsubtext{
         font-style: normal;
         font-weight: 700;
         font-size: 2rem;
         line-height: 40px;
         letter-spacing: 0.01em;
         color: #05174B;
         }
 }
 
 @media screen and (min-width: 1024px) {
     .left{
          padding: 70px 25px 0 0;
     }
 }
 