.commentSection{
    background-color: white;
    min-height: 85vh;
}

.commentInput{
    background-color: white !important;
    border-radius: 0.5rem;
}


.inputBorder{
    border: 1px solid #D1DAE7;
}

.commentHeading{
    font-weight: bolder;
    font-size: 1.2rem !important;
}

.userFullName{
    font-weight: bold;
    font-size: 1.2rem !important;
}

.commentDate{
    font-size: 0.8rem !important;
    font-weight: 500;
}

.commentBody{
    border: 1px solid #D1DAE7;
    border-radius: 0.5rem;
}

.commentText{
    background-color: #F9FAF9;
    border-radius: 0.5rem;
}

.pointer{
    cursor: pointer;
}

.userCommentAvatar{
  width: 2.5rem; /* Adjust the size as needed */
  height: 2.5rem; /* Adjust the size as needed */
  border-radius: 50%;
  object-fit: cover;

}

.comment-container {
    position: relative;
    
   
 
    transition: background-color 0.3s ease;
  }
  
  
  
  .reaction-picker {
    position: absolute;
    bottom: 100%;
    left: 72%;
    z-index: 1100;
    display: block;
    transition: opacity 0.3s ease;
  }
  
  .comment-container:hover .reaction-picker {
    opacity: 1;
  }
  
  .reaction-picker {
    opacity: 0;
    cursor: pointer;
    pointer-events: none;
    transition: opacity 0.3s ease;

    
  }

  .reactions-container{
    padding: 0.5rem;
  border-radius: 2rem;
  box-shadow: 0px 0px 0.5rem rgb(179, 177, 177);
  }
  
  .comment-container:hover .reaction-picker {
    pointer-events: auto;
    opacity: 1;
  }

  .emojis {
    display: flex;
    gap: 10px;
    font-size: 24px;
  cursor: pointer;
  }

  .emoji-picker-icon {
    font-size: 24px;
    cursor: pointer;
  }

  .emojiContent{
    
    background-color: #f9f9f9;
    border-radius: 8px;
    width: 3.5rem;
  }

  .tooltipComment {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 1rem;
    min-width: 12rem;
    box-shadow: 0px 4px 8px rgba(255, 236, 236, 0.1);
    padding: 10px;
    top:2rem ;
    left: 0;
    z-index: 99;
    display: flex;
    flex-direction: column;
  }
  
  .tooltip-item {
    margin: 0;
  }

  .noCommentSection{
    height: 60vh;
    
  }

.emoji-picker > div >em-emoji-picker {
  height: 20rem;
}