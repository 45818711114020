.autolytics-info-hero-section-background {
  background-color: #f6f7fc;
}
.autolytics-info-hero-section-wrapper {
  display: grid;
  gap: 2rem;
}
.autolytics-info-hero-section-heading {
  color: #333;
  font-size: 2rem !important;
  font-weight: 600;
  line-height: 150%; /* 3rem */
  margin-bottom: 2 !important;
}

.autolytics-info-hero-section-description {
  color: #5b5b5b;
  font-size: 1.125rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 2.25rem */
  margin-bottom: 2 !important;
}

.autolytics-info-hero-section-image {
  width: 100%;
}

@media (min-width: 1024px) {
  .autolytics-info-hero-section-wrapper {
    grid-template-columns: 1fr 1fr;
    gap: 8rem;
    align-items: center;
  }

  .autolytics-info-hero-section-heading {
    font-size: 2.5rem !important;
    margin-bottom: 0.75rem !important;
  }
  .autolytics-info-hero-section-description {
    font-size: 1.5rem !important;
  }
}
