.tokenDiv {
  font-family: "Poppins", sans-serif;

  border: 2px dashed black;
  width: 28vw !important;
  padding: 2%;
  word-break: break-all;
  font-size: 1.5rem !important;
}
.copyIcon {
  font-family: "Poppins", sans-serif;

  border: 3px dashed black;
  width: 5vw !important;
  padding: 2%;
  background-color: black;
  color: white;
  font-size: calc(1.2vh + 1.2vw) !important;
  cursor: pointer !important;
}

.changeToken {
  font-family: "Poppins", sans-serif;

  background-color: #ff7e7c !important;
  color: white;
  border-color: #ff7e7c !important;
  cursor: pointer !important;
  font-size: 0.9rem !important;
}
.createdOn {
  font-family: "Poppins", sans-serif;

  font-size: calc(0.65vh + 0.65vw) !important;
  color: rgba(0, 0, 0, 0.6) !important;
}
.tableHeading {
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 1.3rem !important;
}

.headerTable {
  background-color: rgba(99, 108, 121, 0.1) !important;
  font-size: calc(0.8vh + 0.8vw) !important;
}
.tableBody {
  background-color: white !important;
  font-size: calc(0.7vh + 0.7vw) !important;

  box-shadow: 0px 3px 6px rgba(0, 71, 136, 0.05) !important;
  border-radius: 4px !important;
}
