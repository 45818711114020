.feature-autolytics-dashboard-background {
  background-image: url("../../../../assets/featureOfAutolyticsDashboardBackgroundImage.png");
  background-position: top center;
  background-repeat: no-repeat;
}
.feature-autolytics-cards-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-top: 2rem;
}

.autolytics-info-inner-cards-wrapper {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr;
}
.autolytics-info-card-icon {
  width: 19.5rem; /* 312px / 16px = 19.5rem */
  height: 12.1875rem; /* 195px / 16px = 12.1875rem */
}
.autolytics-info-card-heading {
  color: #333;
  font-size: 1.25rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 2.25rem */
  margin-bottom: 0.5rem !important;
}
.autolytics-info-card-description {
  color: #5b5b5b;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.6875rem */
  margin-bottom: 0rem !important;
  letter-spacing: 0.03rem;
}
@media (min-width: 1024px) {
  .feature-autolytics-cards-wrapper {
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    margin-top: 3rem;
  }
  .autolytics-info-inner-cards-wrapper {
    gap: 1rem;
  }
  .autolytics-info-card-heading {
    font-size: 1.5rem !important;
    text-align: center;
    margin-bottom: 1rem !important;
  }
  .autolytics-info-card-heading::after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 10%;
    border-bottom: 0.15rem solid #f4736e;
  }

  .autolytics-info-card-image {
    width: 22.9375rem; /* 367px / 16px = 22.9375rem */
    height: 13.25rem; /* 212px / 16px = 13.25rem */
    margin: 0 auto;
  }
  .autolytics-info-card-description {
    text-align: center;
  }
}
