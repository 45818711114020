.crudIcons {
  font-size: 1rem !important;
}

.notDisabledAdmin {
  filter: invert(68%) sepia(20%) saturate(880%) hue-rotate(314deg)
    brightness(96%) contrast(99%) !important;
  cursor: pointer;
}
.notDisabledActionIcon {
  filter: invert(68%) sepia(20%) saturate(880%) hue-rotate(314deg)
    brightness(96%) contrast(99%) !important;
  cursor: pointer;
  font-size: 1.1rem !important;
}
.notDisabledAdmin:hover {
  transform: scale(1.2);
}
.notDisabledActionIcon:hover {
  transform: scale(1.2);
}
.adminAction {
  background-color: white !important;
  border: none !important;
  /* filter: invert(68%) sepia(20%) saturate(880%) hue-rotate(314deg)
    brightness(96%) contrast(99%) !important; */
}
