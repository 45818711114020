/* common styles */
.made-with-bharat-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  color: white;

  .jewel-white-logo {
    width: 2.25rem;
    height: 2.25rem;
  }
  .jewel-text-white {
    padding-left: 0.5rem;
    color: #fff;
    text-align: center;
    font-size: 2rem !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0 !important;
  }
  .made-with-bharat-text {
    color: #fff;
    font-size: 0.875rem !important;
    font-style: normal;
    font-weight: 500;
    line-height: 1.44rem; /* 164.571% */
    letter-spacing: 0.0175rem;
  }
}
.social-media-icons {
  transition: 0.3s ease-out;
  margin-right: 1rem;
}

/* mobile style  */
.accordion-wrapper {
  background: #f4736e;
  padding: 2.5rem 1.5rem;
  color: white;
}
/* -------Accordion------- */
.footer-accordion .p-accordion-header-link {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: #fff !important;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0.75rem 1rem !important;
}
.footer-accordion > .p-accordion-header-link > .p-accordion-header-text {
  color: #fff;
  font-size: 1.25rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: 150% !important; /* 1.875rem */
}

.footer-accordion > .p-accordion-content {
  background: transparent !important;
  border: none !important;
  color: rgba(255, 255, 255, 0.95) !important;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}
.footer-accordion a {
  color: white;
}
.footer-accordion p {
  margin-top: 0.75rem;
}
.footer-hr {
  background: rgba(209, 218, 231, 0.8);
  opacity: 0.8 !important;
  margin: 0rem !important;
}
.mobile-all-rights-reserved {
  color: #fff;
  font-size: 0.875rem !important;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 1.3125rem */
  text-align: center;
}

/* Desktop styles  */
.footer-background {
  background-image: url("../../../assets/newFooterBackgroundVector.svg");
  background-repeat: no-repeat;
  background-position: left;
  background-size: 100% auto;

  display: flex;
  align-items: center;
  padding-left: 4rem;
  width: 40%;
}

.footer-links-wrapper-parent {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding-right: 4rem;
}
.footer-links-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
.show-desktop-footer .footer-links-heading {
  color: #333;
  font-size: 1.125rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.6875rem */
  margin-bottom: 1rem !important;
}

.show-desktop-footer .footer-links-p {
  margin-top: 0.75rem !important;
}
.footer-links {
  color: #333;
  font-size: 0.875rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}
.footer-links:hover {
  color: #f4736e;
}
.show-desktop-footer .footer-copyright-text {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 2rem !important;
  margin-right: 5rem;
  color: #333;
  text-align: center;
  font-size: 0.875rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.show-desktop-footer {
  display: none;
}
.show-desktop-footer p,
.accordion-wrapper p {
  margin-bottom: 0 !important;
}
.show-desktop-footer a,
.accordion-wrapper a {
  text-decoration: none;
}
.width-fit {
  width: fit-content;
}

@media (min-width: 1024px) {
  .show-desktop-footer {
    display: flex;
    height: 24rem;
    background-color: #fff9f9;
  }
  .accordion-wrapper {
    display: none;
  }
  .made-with-bharat-wrapper {
    gap: 1rem;
    align-items: start;
  }
  .social-media-icons:hover {
    transform: scale(1.5);
  }
}
