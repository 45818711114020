img.thumbnail {
  border: 1px solid #ddd;
  border-radius: 4px; /* Rounded border */
  padding: 5px; /* Some padding */
  width: 8rem; /* Set a small width */
  height: 5rem;
  cursor: pointer !important;
}
.ssBtn
{
  background-color: #73C1ED !important;
  border: none !important;
}
.screenshotIcon
{
  height: 15px !important;
  width: 15px !important;
}
img.thumbnailBroken
{
  border: 1px solid #ddd;
  border-radius: 4px; /* Rounded border */
  padding: 5px; /* Some padding */
  width: 8rem; /* Set a small width */
  height: 5rem;
}
/* Add a hover effect (blue shadow) */
img.thumbnail:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}
.dialogImage {
  height: 100% !important;
  width: 100% !important;
}
.displayThumb {
  display: none !important;
}
