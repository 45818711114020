.dialog-dropdown {
  background-color: #f9f9f9 !important;
  border: none !important;

  /* color: black !important; */
}

.dropdown-heading {
  color: rgba(0, 0, 0, 0.4) !important;
  font-size: 0.85rem !important;
}

.p-inputtext {
  background-color: #f9f9f9 !important;
  border: none;
}

.instanceListText {
  text-decoration: underline;
  cursor: pointer;
}

.p-button-icon-only {
  background-color: #f9f9f9 !important;

  border: none !important;
  color: #ff7e7c !important;
}

.p-datatable-thead {
  padding: 2px !important;
}

.p-datatable-header {
  text-align: center !important;
  background-color: rgba(211, 211, 211, 0.07) !important;

  /* border-top: none !important; */
}

/* .p-component {
  font-size: calc(0.8vh + 0.8vw) !important;
} */

/* .pi.pi-filter-icon.pi-filter {
  color: grey !important;
  font-size: calc(0.8vh + 0.8vw) !important;
} */