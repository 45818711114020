.extentPill {
  background-color: #f6f7fc !important;
  color: rgba(0, 0, 0, 0.6) !important;
  border-radius: unset !important;
}
.extentPill.active {
  color: #000000 !important;
  border-bottom: 2px solid #ff7e7c !important;
  border-radius: unset !important;
}

.wrapperPills
{
    background-color: white !important;
    border-radius: 24px !important;
    padding: 4px !important;
}

.tcDetailPill
{
  background-color: white !important;
  color: #6E6893 !important;
    border-radius: 24px !important;

}

.tcDetailPill.active
{
  background-color: #FF7E7C1A !important;
  color: #FF7E7C !important;
  border-radius: 24px !important;

}
.extentUl
{
    border-bottom: 2px solid #C6C2DE !important;

}
.tab-content {
  font-size: 1rem !important;
}
.reportHead {
  color: #023b59 !important;
}
.exeReportHeading
{
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  color:  #263238 !important;

}
/* .reportHead span {
  font-size: 1.9rem !important;
} */

.chartExtent 
{
  max-width: 26vw !important;
}

.infraCardNew
{
  display: flex !important;
  flex-direction: column !important;
}
.extentCard{
  background: #FFFFFF;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 16px !important;
  padding: 1rem 1.75rem;
  border: 0 !important;
  justify-content: space-between;
}
.extentHeading{
  font-weight: 600;
  font-size: 14px !important;
  padding: 0.5rem 0 0.5rem 0;
  border-bottom: 0.587519px solid #E5E5EF;
  margin-bottom: 0.75rem;
}

.showTCIcon
{
  font-size: 15px !important;
}
.clockIcon
{
  font-size: 0.65rem !important;
  color: white !important;
}
.expTimePill
{
  background-color: #9E9E9E !important;
  border-radius: 10px !important;
  color: white !important;
}
.report-env{
  background: #ffd9d9;
  padding: 2px 10px;
  margin: 0 5px;
  border-radius: 6px;
  color: #FF7E7C;
}
.editBuildIcon
{

    cursor: pointer !important;
color: #FF7E7C !important;
  font-size: 0.8rem !important;
}
.editBuildIcon:hover
{
    cursor: pointer !important;

}

.report-status{
  color: #FFFFFF;
  padding: 2.5px 11px;
  margin: 0 5px;
  font-size: 0.75rem !important;
}

.execution-headers-legend
{
  color : rgba(14, 34, 56, 0.6) !important;
  font-size: 0.85rem !important;
  letter-spacing: 0.01em !important;

}
.execution-headers{
  color: #6B6B6B;
}
.project-name{
  word-break: break-word !important;
  color: #0E2238;
  font-weight: 500;
  margin: 0;
}
.card-grid-headers{
  font-size: 14px !important;
  color: #0E2238;
  font-weight: 600;
}
.suite-name{
  margin: 0 5px;
  padding: 0 5px;
  border-right: 1.5px solid rgba(107, 107, 107, 0.3);
}

.cardHeader {
  color: #023b59 !important;
}
.cardHeadercontent {
  color: #0E2238 !important;
  word-break: break-word !important;
}
.wrapClass {
  white-space: normal;
}
.input-switch-execution{
  font-size: 12px !important;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.backAuto a {
  text-decoration: none !important;
}
.backToAutolytics {
  color: #ff7e7c !important;
  border: 1px solid #ff7e7c !important;
}
.infraCards {
  /* max-width: 25vw !important; */
  border: 2px solid white !important;

  /* border-bottom-left-radius: 30% !important;
  border-top-right-radius: 30% !important;
  border-top-left-radius: 6% !important;
  border-bottom-right-radius: 6% !important; */
}

.infraCards:hover {
  /* border-bottom : 3px solid #ff7e7c !important; 
  border-left: 3px solid #ff7e7c !important; */
  border: 2px solid #ff7e7c !important;
  cursor: pointer !important;
}
.p-datatable-row-expansion {
  background-color: #f6f7fc !important;
  border: 2px outset #f8f9fa !important;
}
.p-row-toggler-icon {
  /* font-size: calc(0.7vh + 0.7vw) !important; */
}
.expandDataTable {
  max-width: 95% !important;
}
.parent {
  height: 98vh;
  /* min-height: max-content !important; */
  width: 100%;
  overflow: hidden;
  position: relative !important;
}

.child {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -17px;
  overflow-y: scroll;
  overflow-x: hidden !important;
}
.splitterParent
{
  height: 100vh !important;
  overflow: auto !important;
}
.secondparent {
  height: 98vh;
  width: 100%;
  
  overflow-x: hidden;
  position: relative !important;
}
.secondchild {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
}
.p-splitter-gutter,
.p-splitter-gutter-handle {
  background-color: transparent;
  /* background: #9e9e9e !important; */
}
.maxWidthDataTable {
  max-width: 15vw !important;
  white-space: normal !important;
}
.expCompTime {
  font-size: 0.85rem !important;
  background-color: #9e9e9e !important;
  color: white !important;
  border-radius: 30px !important;
}
