.superAdminSection {
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

.superHeader {
  font-weight: 600 !important;
  color: #263238 !important;
}
.superAdmin-icon {
  filter: invert(68%) sepia(20%) saturate(880%) hue-rotate(314deg)
    brightness(96%) contrast(99%) !important;
}

.superAdminUsername {
  border-bottom: 2px solid #ff7e7c;
}

.actionIcon {
  cursor: pointer !important;
}

.actionIcon:hover {
  transform: scale(1.2);
  cursor: pointer !important;
}
.InfoLink {
  font-size: 0.85rem !important;
  font-family: "Poppins", sans-serif !important;
}
.addAdminsLink {
  font-size: 0.85rem !important;
  font-family: "Poppins", sans-serif !important;
}
.InfoLink.active {
  background-color: #f6f7fc !important;
  border-bottom: 2px solid #ff7e7c !important;
  color: black !important;
}
.addAdminsLink.active {
  background-color: #f6f7fc !important;
  border-bottom: 2px solid #ff7e7c !important;
  color: black !important;
}
.pCheck {
  font-size: 0.7rem !important;
  color: green !important;
}
.pCheck-not {
  font-size: 0.7rem !important;
  color: red !important;
}
