.mainHead {
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 56px !important;
  line-height: 84px !important;

  color: #05174b !important;
}
.subHead {
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 17.5px !important;
  line-height: 28px !important;
  /* or 160% */

  letter-spacing: 0.01em !important;

  color: #05174b !important;
}
.demobtn {
  background-color: #ff7e7c !important;
  color: #ffffff !important;
}
