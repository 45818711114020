.testcaseTableheader {
  background-color: rgba(211, 211, 211, 0.5) !important;
  border: 0.5px solid black !important;
}
.testcaseTablerow {
  background-color: white !important;
  border: 0.5px solid black !important;
}

.createTestcaseDialog .p-dialog-footer {
  border: 1px solid #ced4da !important;
}
.removeItem {
  cursor: pointer !important;
  font-size: 1rem !important;
}
.inputHeight {
  min-height: 7.5% !important;
  max-height: 7.5% !important;
  border: 1px solid #ced4da !important;
}
.inputHeight .p-dropdown-label.p-inputtext {
  border: unset !important;
}
.chipHeight .p-inputtext.p-chips-multiple-container {
  overflow: auto !important;
  max-height: 9vh !important;
  border: 1px solid #ced4da !important;
  max-width: 20vw !important;
}
.table-column {
  min-width: fit-content !important ;
  word-wrap: break-word !important;
  white-space: break-spaces !important;
}
.deletePrompt {
  max-width: 90% !important;
  word-break: break-all !important;
}
.createPill {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 1rem !important;
  border-radius: unset !important;
}
.createPill.active {
  color: #000000 !important;
  border-bottom: 3px solid #ff7e7c !important;
  border-radius: unset !important;
}
.ulpill {
  /* background-color: #f6f7fc !important; */
}

#beforeFile,
#afterFile {
  border: 1px solid #ced4da !important;
}

/* .selectAllHeader > .p-column-header-content
{
 display: none !important;
} */
