.underText{
    font-family: 'Poppins',sans-serif !important;

font-weight: 400 !important;
font-size: 14px !important;
line-height: 34px !important;
/* or 189% */

letter-spacing: 0.06em !important;

color: #000000 !important;

}
.whatisHead{
    font-family: 'Poppins',sans-serif !important;

font-weight: 400 !important;
font-size: 20px !important;
line-height: 30px !important;

color: #023B59 !important;

}
.customCard{
    background: #FFFFFF !important;
box-shadow: 0px 2px 8px rgba(61, 100, 211, 0.12) !important;
border-radius: 16px !important;
max-width: 350px !important;
min-height: 350px !important;
}

.whatisData{
    font-family: 'Poppins',sans-serif !important;
font-weight: 400 !important;
font-size: 12px !important;
line-height: 20px !important;
text-align: left;
/* or 157% */
color: #000000 !important;
}
.circleCheckIcon
{
    filter: invert(76%) sepia(37%) saturate(2117%) hue-rotate(304deg) brightness(91%) contrast(125%) !important;
}