.you-may-card-wrapper {
  padding: 1.5rem;
}

.you-may-card-icon {
  width: 5.5rem;
  height: 5.5rem;
}
.you-may-card-heading {
  color: #333;
  font-size: 1.5rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 2.25rem */
  margin-top: 2rem;
}
.you-may-card-description {
  color: #5b5b5b;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
  margin-bottom: 2rem !important;
}

@media (min-width: 1024px) {
  .you-may-card-wrapper {
    padding: 2rem;
  }
  .you-may-card-description {
    height: calc(20%);
  }
}
@media (min-width: 1500px) {
  .you-may-card-description {
    height: calc(15%);
  }
}
