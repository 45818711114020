.advantage-card-wrapper {
  border-radius: 1rem;
  background: rgba(255, 168, 167, 0.1);
  overflow: hidden;
  padding: 1.5rem;
}
.advantage-card-icon {
  width: 6rem;
  height: 6rem;
}
.advantage-card-heading {
  color: #333;
  font-size: 1.25rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 2.25rem */
  margin-top: 2rem;
}
.advantage-card-description {
  color: #5b5b5b;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.6875rem */
  margin-bottom: 0rem !important;
}
@media (min-width: 1024px) {
  .advantage-card-wrapper {
    padding: 2rem;
  }
  .advantage-card-heading {
    font-size: 1.5rem !important;
  }
  .advantage-card-description {
    font-size: 1.125rem !important;
  }
}
