.specialized-modules-card-wrapper {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.specialized-modules-card-icon {
  width: 5.5rem;
  height: 5.5rem;
}

.specialized-modules-card-heading {
  color: #333;
  font-size: 1.5rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 2.25rem */
  margin-top: 2rem;
}
.specialized-modules-card-description {
  color: #5b5b5b;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
  margin-bottom: 0 !important;
 
}

@media (min-width: 1024px) {
  .specialized-modules-card-wrapper {
    padding: 2rem;
  }
}
