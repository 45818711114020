.work-minutes-user-capabilities-background {
  background: url("../../../../assets/userCapabilitiesBackground.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.work-minutes-user-capabilities-cards-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-top: 2rem;
}
@media (min-width: 1024px) {
  .work-minutes-user-capabilities-cards-wrapper {
    grid-template-columns: 1fr 1fr;
    margin-top: 3rem;
  }
}
