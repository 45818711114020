.testpad-scroll-animation-desktop-wrapper {
  display: grid;
  grid-template-columns: 3fr 7fr;
  margin-top: 3rem;
  align-items: center;
}

.testpad-scroll-animation-desktop-wrapper .image-wrapper {
  transition: all 0.3s;
}

.testpad-scroll-animation-desktop-wrapper .testpad-card-heading:hover {
  color: #ff7e7c;
  cursor: pointer;
}
.testpad-scroll-animation-desktop-wrapper .testpad-card-heading-active {
  color: #ff7e7c;
}
.testpad-scroll-animation-desktop-wrapper .description-and-link-wrapper {
  height: 0;
  overflow: hidden;
  transition: all 0.7s;
}
.testpad-scroll-animation-desktop-wrapper .description-and-link-wrapper-active {
  height: 6rem;
}
.testpad-scroll-animation-desktop-wrapper .image-wrapper {
  height: 27rem;
  position: relative;
}
.testpad-scroll-animation-desktop-wrapper .image {
  transition: all 0.7s;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
}
.testpad-scroll-animation-desktop-wrapper .active-image {
  opacity: 1;
}

.testpad-scroll-animation-desktop-wrapper .p-dialog .p-dialog-content{
  background: none !important;
}
