.p-sidebar-mask {
  top: 3.5rem !important;
}
.p-sidebar-right .p-sidebar {
  width: 80% !important;
}
.p-sidebar {
  border: none !important;
  box-shadow: none !important;
}
.p-sidebar-header {
  display: none !important;
}

.mobile-header-link-wrapper {
  margin-top: 0.5rem;
  width: fit-content;
}
.mobile-header-link {
  text-decoration: none !important;
}
.mobile-header-link > p {
  font-size: 1.125rem !important;
  line-height: 1.75rem /* 28px */;
  font-weight: 600;
  color: #333;
  text-decoration: none !important;
}
.active-mobile-header-link {
  color: #f4736e !important;
}
.mobile-header-link p {
  padding: 0.5rem 0rem;
  margin-bottom: 0rem !important;
}

/* -------Accordion------- */
.mobile-navbar-drawer .p-accordion-header-link {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 1rem 0rem !important;
}
.mobile-navbar-drawer .p-accordion-header-link .p-accordion-header-text {
  color: #333;
  font-size: 1.125rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.875rem */
}

.mobile-navbar-drawer .p-accordion .p-accordion-content {
  padding: 0rem 0rem 1rem 1.25rem !important;
}
.mobile-navbar-drawer .p-accordion-content {
  border: none !important;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}
.mobile-navbar-drawer p {
  margin-bottom: 0rem !important;
}
.mobile-navbar-drawer a {
  text-decoration: none !important;
}
.margin-left {
  margin-left: 1.5rem;
}
.flex-grow {
  flex-grow: 1;
}
.width-full {
  width: 100% !important;
}
.col-gap {
  column-gap: 1.5rem;
}
.col-gap-0-5rem {
  column-gap: 0.5rem;
}
