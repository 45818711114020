.filterIcon {
  font-size: 1.2rem !important;
}

/* .filterProject:hover{
text-decoration: underline;
} */

/* .filterProject{
  position: relative;
} */

.dropdown-menu-custom {
  position: absolute;
  top: 2rem;
  /* Position below the button */
  text-align: center;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  /* Ensure it appears above other content */
  width: 95%;
  border-radius: 8px;
}

.dropdown-menu-custom>a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  text-align: center;
}

.activeSelect {
  color: #f4736e !important;
}

.filter-container {
  position: relative;
  display: inline-block;
}

.filterDropdowns {
  border-radius: 8px;
  position: absolute;
  top: 100%;
  /* Position below the button */
  right: 4%;
  /* Align to the right */
  z-index: 1000;
  /* Ensure it appears above other elements */
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 25rem;
}

.filterConfigDropdowns {
  border-radius: 8px;
  position: absolute;
  top: 100%;
  /* Position below the button */
  right: 0;
  /* Align to the right */
  z-index: 1000;
  /* Ensure it appears above other elements */
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 35%;
}
.filterCard {
  display: flex;
  flex-direction: column;
  width: 17.4rem !important;
  min-height: 20rem !important;
  max-height: 26rem !important;
  padding: 1rem;
  margin: 0.4rem;
  background-color: white;
  border-radius: 1.5rem;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);
}
.chart-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-container canvas {
  width: 17.2rem !important;
  min-height: 17rem !important;
}

.doughnut-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.doughnut-container canvas {
  width: 11rem !important;
  height: 10rem !important;
  margin-left: 1rem !important;
}
.bar-container{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bar-container canvas{
  width: 16rem !important;
  height: 15rem !important;
}

.header-count {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}

.testcaseHeaderCard {
  position: relative;
  padding: 1rem 1rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 32px 0px #3333331A;
  z-index: 20;
  /* Ensure this is higher than .all-projects-container */
}

.filterCard .pill-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  /* Adjust the gap between pills as needed */
}



.stabilityCard {
  display: flex;
  flex-direction: column;
  width: 17.2rem !important;
  padding: 1rem 1rem;
  margin: 0.5rem;
  height: 10rem !important;
  overflow: hidden;
  background-color: white;
  border-radius: 1.5rem;
  box-shadow: 0px 2px 10px 0px #3333331A;
}

.dashboard-progress-bar {
  height: 1rem !important;
  border-radius: 1rem !important;
}



.dashboard-dialog-dropdown {
  background-color: #ffffff !important;
  border: 1px solid #dbdbdb !important;
  max-width: 10rem !important;
  min-width: 8rem !important;

  /* color: black !important; */
}

.stability-dialog-dropdown {
  background-color: #ffffff !important;
  border: 1px solid #dbdbdb !important;
  max-width: 12rem !important;
  min-width: 10rem !important;

  /* color: black !important; */
}


.dashboard-dialog-dropdown>.p-inputtext,
.stability-dialog-dropdown>.p-inputtext {
  background-color: #ffffff !important;
  border: none;
}

.see-all-projects {
  cursor: pointer;
  color: blue;
}

.all-projects-container {
  position: absolute;
  top: 104%;
  left: 0;
  right: 0;
  background: white;
  border: none;
  z-index: 10;
  /* Ensure this is lower than .testcaseHeaderCard */
  box-shadow: 0px 4px 32px 0px #3333331A;
  border-radius: 1rem;
  scrollbar-width: none;
  /* For Firefox */
  max-height: 0;
  /* Initially hidden */
  overflow: hidden;
  /* Hide overflow during animation */
  opacity: 0;
  /* Initially hidden */
}

.all-projects::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}


.all-projects {
  display: flex;
  flex-wrap: wrap;
  max-height: 220px;
  overflow-y: auto;
  gap: 4px;
}

.filterPill {
  flex: 0 1 auto;
  /* Allow pills to shrink and grow as needed */
  white-space: nowrap;
  /* Prevent text from wrapping inside the pill */
  padding: 0.5rem;
  background-color: #ffecec;
  /* Adjust the background color as needed */
  border-radius: 0.25rem;
  /* Adjust the border radius as needed */
  margin: 0.25rem 0;
  /* Adjust the margin as needed */
}

.footerCards {
  background-color: white !important;
  padding: 1rem 2rem;
  border-radius: 1.5rem;
  box-shadow: 0px 4px 32px 0px #3333331A;
}

.projectCountHeader {
  color: #f4736e;
}

.projectCountHeader:hover {
  text-decoration: underline;
}

.fadeText {
  color: #9c9c9c;
  font-size: 1rem !important;
  text-align: center;
  margin-top: 3rem;
}

.durationText {
  color: #9c9c9c;
}

.trend-chart {
  background-color: white;
  border-radius: 4px;
  height: 65vh;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.11);
}

@media (min-width: 1350px) and (max-width: 1549px){
  .filterCard{
    width: 17.3rem !important;
  }
  .stabilityCard{
    width: 17.3rem !important;
    height: 12rem !important;
  }
}
@media (min-width: 1550px) and (max-width: 1749px){
  .filterCard{
    width: 22rem !important;
  }
  .stabilityCard{
    width: 22rem !important;
    height: 12rem !important;
  }
}
@media (min-width: 1750px) and (max-width: 1999px) {
  .filterCard {
    width: 26.5rem !important;
  }
  .stabilityCard{
    width: 26.5rem !important;
    height: 12rem !important;
  }
}
@media (min-width: 2000px) {
  .filterCard {
    width: 35rem !important;
  }
  .stabilityCard{
    width: 35rem !important;
    height: 12rem !important;
  }
}