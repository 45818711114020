.customPaginator
{
    font-weight: normal !important;
}

.pageLinks
{
    background-color: transparent;
    border: 0 none;
    color: #6c757d;
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s !important;
    transition-duration: 0.2s !important; 
    transition-timing-function: ease !important;
    transition-delay: 0s !important;
    transition-property: box-shadow !important;
    border-radius: 50%;
    cursor: pointer !important;
}

.activePage
{
    background: #EEF2FF !important;
    border-color: #EEF2FF !important;
    color: #4338CA !important;
}
.activePage:focus
{
        outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #C7D2FE;
}
.pageLinks:hover
{
    background: #e9ecef;
    border-color: transparent;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}

.disabledLinks
{
    cursor: default !important;
    opacity: 0.6 !important;
}
.disabledLinks:hover
{
    background: none !important;
}

.prevNextBtn
{
    font-size: 0.8rem !important;
}

.totalEle
{
    color: #6c757d !important;
}
.rowsPerPageDropdown
{
    background: #ffffff !important;
    border: 1px solid #ced4da !important;
    /* transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s; */
    border-radius: 6px !important;
}