.tableBadge.p-badge {
  min-width: 1rem !important;
  height: 1rem !important;
  line-height: 1rem !important;
}

.inactiveJobs.p-badge {
  background-color : grey !important;
}

.schedulerTooltip>* {
  box-shadow: none !important;
}
