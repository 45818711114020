.allTcCard {
  /* min-height: 30vh !important; */
  overflow-y: scroll !important;
  min-height: 40vh !important;
  max-height: 40vh !important;
}
.buttonGroup {
  position: absolute !important;
  right: 0 !important;
  bottom: 0 !important;
}


.notSelected{
  opacity: 0.5 !important;
}

.datatable-disabled .p-datatable-wrapper {
  pointer-events: none; 
  opacity: 0.5; 
}

.stepDataTooltip{
  font-size: 1.2rem !important;
}