.notification{
    font-size: calc(0.74vw + 0.74vh);
    display: flex;
    height: 4vh;
    margin-top: auto !important;
    justify-content: center;
    align-items: center;
  }
  .notification:hover{
    color: #ff7e7c !important;
  }
  .tab-nav {
    position: relative; 
    display: flex; 
    align-items: center; 
    border: 1px solid rgba(255, 126, 124, 0.7) !important;
    overflow: hidden; 
    border-radius: 12px;
    width: 18rem;
    height: 2rem;
  }
  
  .tab-btn {
    flex: 1; 
    height: 100%; 
    min-width: 20px;
    padding: 12px 24px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 0.8rem !important;
    transition: background-color 0.3s, color 0.3s;
  }
  
  
  .read-btn {
    border: 1px solid rgba(244, 115, 110, 1);
    color: rgba(244, 115, 110, 1); 
    font-weight: 600;
  }
  
  .tab-active {
    background-color: #ff7e7c; 
    color: #fff; 
  }
  .mark-all-read {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    color: #FF7E7C;
    font-weight: 500;
    font-size: 1.1rem !important;
  }
  .mark-all-read-disabled{
    font-size: 1.1rem !important;
    background: none;
    border: none;
    color: grey;
    font-weight: 500;
    cursor:not-allowed !important;
  }
  .mark-all-read:hover{
    transform: scale(1.1);
  }  
  .notification-rectangles {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .notification-rectangle {
    width: 600px;
    min-height: 80px;
    border-radius: 8px;
    padding: 8px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .unread-in-all .message {
    padding-right: 32px; 
  }
  
  .notification-rectangle .faDotCircle {
    color:#FF7E7C;
    position: absolute;
    top: 8px; 
    right: 8px;
    font-size: 8px !important;
  }
  .unread-in-all{
    background: rgba(255, 126, 124, 0.1);
  }
  .read{
    background: rgba(249, 249, 249, 0.80);
  }
  .notification-rectangle.read:hover{
    transform: scale(1.02);
    cursor: pointer;
  }
  .notification-rectangle.unread-in-all:hover {
        transform: scale(1.02);
        cursor: pointer;
  }
  .notification-time{
    font-size: 0.7rem !important;
    color: grey !important;
  }
  .message
  {
    font-size: 14px !important;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
  }
  .no-notification {
    max-width: 70% !important;
    max-height: 70% !important; 
    height: auto !important; 
    width: auto !important; 
    margin-top: 3rem !important;
    margin-left: 4rem !important;
  }