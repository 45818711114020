.user-capabilities-inner-cards-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.user-capabilities-card-icon {
  width: 6rem;
  height: 6rem;
}
.userCapabilities-card-heading {
  color: #333;
  font-size: 1.25rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 2.25rem */
  margin-bottom: 0.5rem !important;
}
.userCapabilities-card-description {
  color: #5b5b5b;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.6875rem */
  margin-bottom: 0rem !important;
}
@media (min-width: 1024px) {
  .user-capabilities-inner-cards-wrapper {
    flex-direction: row;
    gap: 2rem;
  }
  .userCapabilities-card-heading {
    font-size: 1.5rem !important;
  }
  .userCapabilities-card-description {
    font-size: 1.125rem !important;
  }
}
