.signup {
  overflow-x: hidden !important;
}
.chainback {
  background-image: url("../../../assets/chainLogo.jpg") !important;
  background-repeat: repeat !important;
  min-height: 100vh !important;
  background-size: 5% !important;
  position: relative !important;
}
.comingLeft {
  transition: all 1s ease-out;
  animation: comingfromLeft 1s forwards;
}
.comingRight {
  transition: all 1s ease-out;
  animation: comingfromRight 1s forwards;
}
@keyframes comingfromLeft {
  0% {
    transform: translateX(-100%);

    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes comingfromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
.imageLogo {
  /* display: block !important;
        margin-left: auto !important;
        margin-right: auto !important; */
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 15% !important;
}
.authback {
  min-height: 100vh !important;
  /* background-image: url("../../../assets/authBack.svg") !important; */
  background: url("../../../assets/authBack.svg"),
    linear-gradient(180deg, #d0e0eb 0%, rgba(208, 224, 235, 0) 100%);
  background-repeat: no-repeat !important;
  background-position: center !important;
  position: relative;
}

.crossicon {
  position: absolute !important;
  top: 7px;
  right: 14px;
  height: 5% !important;
  width: 5% !important;
  border: 1px solid black !important;
  border-radius: 20% !important;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25);
  /* border: 1px solid black !important; */
  /* border-radius: 25% !important;  */
}
.crossicon:hover {
  transform: scale(1.08) !important;
  /* border: 1px solid black !important;
 border-radius: 20% !important;
 box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25); */
}
.closebtn {
  float: right !important;
}

@media screen and (max-width: 992px) {
  .chainback {
    display: none;
  }
  .authback {
    background-image: url("../../../assets/chainLogo.jpg") !important;
    background-repeat: repeat !important;
    min-height: 100vh !important;
    background-size: 5% !important;
  }
  .imageLogo {
    /* display: block !important;
        margin-left: auto !important;
        margin-right: auto !important; */
    position: absolute;
    top: 8%;
    left: 50% !important;
    right: 50% !important;
    transform: translate(-50%, -50%);
    height: 9% !important;
  }
  .crossicon {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(192deg)
      brightness(102%) contrast(104%) !important;
    box-shadow: none !important;
    border: none !important;
    /* background-color: yellowgreen !important;
    border-radius: 50px; */
  }
}

@media screen and (max-width: 768px) {
  .crossicon {
    height: 7% !important;
    width: 7% !important;
    top: 5px !important;
  }
}
