#scrolltop {
  display: none;
  position: fixed;
  bottom: 30px;
  right: 5px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #ff7e7c;
  color: white;
  cursor: pointer;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 9px;
  padding-bottom: 4px;
  border-radius: 50%;
  font-size: calc(1vh + 1vw) !important;
}

#scrolltop:hover {
  background-color: #555;
}
