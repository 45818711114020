.healthPill {
  background-color: white !important;
  color: #a6a6a6 !important;
  font-size: 1rem !important;
  /* border-radius: 32px !important; */
}
.health-itemOverview {
  background-color: white !important;
  border-top-left-radius: 32px !important;
  border-bottom-left-radius: 32px !important;
}
.health-itemTimeline {
  background-color: white !important;
  border-top-right-radius: 32px !important;
  border-bottom-right-radius: 32px !important;
}

.healthPillOverview {
  border-top-left-radius: 32px !important;
  border-bottom-left-radius: 32px !important;
}
.healthPillTimeline {
  border-top-right-radius: 32px !important;
  border-bottom-right-radius: 32px !important;
}
.healthPill.active {
  color: #ff7e7c !important;
  background-color: rgba(54, 97, 235, 0.1) !important;
  border-radius: 32px !important;
}
.healthCards {
  min-height: 230px !important;
  min-width: 100% !important;
  background-color: white !important;
  border-radius: 5px !important;
  margin-top: 5% !important;
}
.healthCheck {
  font-family: "Poppins", sans-serif !important;
}
.currentExe > span {
  color: #ff7e7c !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 0.8rem !important;
  line-height: 12px !important;
  text-align: center !important;
  background-color: #e5e5e5 !important;
  border-radius: 10px !important;
}
.expectedExeStatus > span {
  color: white !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 0.8rem !important;
  line-height: 12px !important;
  text-align: center !important;
  border-radius: 10px !important;
}
.previousExeStatus > span {
  color: white !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 0.8rem !important;
  line-height: 12px !important;
  text-align: center !important;
  border-radius: 10px !important;
}

.prevExeInfo {
  min-height: 180px !important;
}

.nextExeTime {
  min-height: 150px !important;
}

.completion-time .title {
  font-size: 0.8rem;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #023b59;
}
.completion-time .data {
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  font-size: 0.7rem;
  color: #ff7e7c;
}

.healthCardsHeading {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #023b59;
}
.projectDetails {
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 21px;
  letter-spacing: 0.01em;
}

.project-details > .row {
  min-height: 200px !important;
}

.prevRunData {
  font-style: normal;
  line-height: 21px;
  letter-spacing: 0.01em;
}

.prevRunData .prevTime {
  font-size: 0.7rem;
  color: #ff7e7c !important;
}
.prevRunData .prevTimeHeading {
  font-size: 0.8rem;
  color: #023b59 !important;
}

.pDetailsData {
  color: #000000;
}
