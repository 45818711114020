.crudActionIcons {
    color: #ff7e7c !important;
    cursor: pointer;
    font-size: 1.1rem !important;
}

.crudActionIcons:hover {
    transform: scale(1.1) !important;
}

.restoreTooltip>* {
    box-shadow: none !important;
}

.disabledCrud {
    color: #9e9e9e !important;
    cursor: not-allowed !important;
    font-size: 1.1rem !important;
}