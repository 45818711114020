.specailized-modules-cards-wrapper {
  margin: 3rem 0rem 1.5rem 0rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}
@media (min-width: 1024px) {
  .specailized-modules-cards-wrapper {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
  }
}
