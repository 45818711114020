.reqSection {
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

.headerTest {
  color: #205072 !important;
  font-size: 1.3rem !important;
  font-weight: 600 !important;
}

.propertiesCard {
  border-radius: 10px !important;
  background-color: white !important;
}

.assignDropdown {
  width: 14rem;
}

.errMsgDiv {
  min-height: 60vh !important;
  min-width: 45vw !important;
  border-radius: 10px !important;
  background-color: #faebeb !important;
  border-left: 4px solid #ab0404 !important;
}

.reqFormInputs {
  border-radius: 6px !important;
  border: 1px solid #ced4da !important;
}

.errorBadge {
  min-width: 1.1rem;
  height: 1.1rem;
  line-height: 1.1rem;
}

.excel-dialog>.p-dialog-content {
  min-height: 75vh !important;
}

.excelLoadingGif {
  max-height: 50vh !important;
  width: -webkit-fill-available !important;
  padding-left: 15% !important;
  padding-right: 15% !important;
}

.p-fileupload-content {
  padding: 0rem 1rem !important;
}

.uploadButton {
  height: 6vh !important;
}

.p-button.p-fileupload-choose {
  height: 6vh !important;
}

.cancelButton {
  height: 6vh !important;
}

.wrapperPillsMlab {
  background-color: white !important;
  border-radius: 24px !important;
  padding: 4px !important;
}

.tcDetailPillMlab {
  background-color: white !important;
  color: #6E6893 !important;
  border-radius: 24px !important;

}

.tcDetailPillMlab.active {
  background-color: #FF7E7C1A !important;
  color: #FF7E7C !important;
  border-radius: 24px !important;

}

.createNew {
  position: relative;
  display: inline-block;
}

.createNewDiv {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #D1DAE7;
  z-index: 1;
  border-radius: 0.5rem !important;
}


.createNewDivElements {
  cursor: pointer;
}

.createNewDivElements:hover {
  background-color: aliceblue;
}

.createNewDivElementsDisabled {
  color: grey !important;
}

.createNewDivElementsDisabled:hover {
  cursor: not-allowed !important;
}

.createNewDivElementsLoading {
  color: grey !important;
}