.revamp-sideBar {
  /* background-color: rgba(38, 50, 56, 1) !important; */
    /* background-color: rgba(0, 160, 227, 0.1) !important; */
    background-color: #f6f7fc !important;
filter: drop-shadow(0 0.2rem 0.3rem rgba(0, 0, 0, 0.3)) !important;
  width: calc(15vh + 15vw);
  min-height: 100vh;
  z-index: 4 !important;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  /* margin: 0;
  padding: 0;
  width: 200px;
  background-color: #f1f1f1;
  position: fixed;
  height: 100%;
  overflow: auto; */
}
.parentSideBar {
  height: 70vh !important;
  min-height: max-content !important;
  width: 100%;
  overflow: hidden;
  position: relative !important;
}

.childSideBar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -17px;
  overflow-y: scroll;
  overflow-x: hidden !important;
}

.topNav {
  margin-left: calc(10vh + 10vw);
  min-height: 8vh;
  background-color: #ffffff !important;
  top: 0%;
  z-index: 10 !important;
  position: fixed !important;
  width: calc(90vw);
}
.isActive {
  background-color: rgba(0, 160, 227, 0.1) !important;
  color: #ff7e7c !important;
  border-left: 3px solid rgba(255, 126, 124, 1) !important;
}
.iconDiv.isActive a {
  color: #ff7e7c !important;
  border-left: none !important;
}

.mainData {
  min-height: 92vh !important;
  background-color: #f6f7fc !important;
  /* box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08) !important; */
  /* margin-top: 8vh; */
  /* position: relative !important; */
  /* background-color: black !important; */
}

.collapseBtn {
  cursor: pointer !important;
  width: 5vw !important;
}
.iconDiv {
  cursor: pointer !important;
}

.notificationIcon {
  font-size: 1.1rem !important;
}

.iconDiv a,
a.homecards,
.iconDropdown {
  text-decoration: none !important;
  color: #878787 !important;
  cursor: pointer !important;
  font-size: calc(0.74vw + 0.74vh);
}

.userProfile{
  text-decoration: none !important;
  color: #2C2C2C;
}

.iconDiv a:hover {
    color: #ff7e7c !important
}
/* a.homecards,
.iconDropdown:hover {
  color: #ff7e7c !important
} */
.sideBarElements {
  margin-top: 1vh !important;
  /* color: #878787 !important; */
  /* word-wrap: break-word !important; */
  word-break: break-word !important;
}
.revamp-sideBarOpenClose {
  background-color: white !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  cursor: pointer !important;
  z-index: 10 !important;
  margin: 0.5rem 0rem 0rem 0rem;
  transform: translateX(50%);
}
.revamp-sideBarOpenCloseIcon {
  color: #ff7e7c;
  font-size: 1.5rem !important;
}

.collapseSideBar {
  display: none !important;
}

.jewel-text {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: calc(1.5vh + 1.5vw) !important;
  color: #2C2C2C !important;

  /* color: #ffffff; */
  padding-left: 10px !important;
  display: flex;
  align-items: center !important;
}

.profileCard {
  background-color: #fff !important;
  filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
  cursor: pointer !important;
  /* background-color: #2d3948 !important; */
  /* border: 1px solid #405261 !important; */
}
.jewel-logo {
  height: calc(2vh + 2vw) !important;
  display: flex;
  align-items: center !important;
}
.avatar {
  height: 2rem !important;
  /* width: 6vw !important; */
  border-radius: 50% !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.profileName {
  /* font-family: "Poppins", sans-serif; */
  word-wrap: break-word !important;
  font-style: normal;
  font-weight: 600;
  font-size: calc(0.7vh + 0.7vw) !important;
  /* color: white; */
}

.profileUsername {
  font-family: "Poppins", sans-serif;
  word-break: break-all !important;
  font-style: normal;
  font-weight: 400;
  font-size: calc(0.6vh + 0.6vw) !important;
  color: #777787;
}
.logout {
  /* position: absolute !important; */
  /* bottom: 5px !important;  */
  /* color: white !important; */
  font-size: calc(0.74vw + 0.74vh);
  display: flex;
  height: 4vh;
  margin-top: auto !important;
  justify-content: center;
  align-items: center;
}
.logout:hover {
  color: #ff7e7c !important;
}
.logoutmenu {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: calc(0.6vh + 0.6vw) !important;
  border: 2px solid #f7e7e9 !important;
  right: auto;
  left: 50% !important;
  top: 100% !important;
  -webkit-transform: translate(-50%, 0) !important;
  -o-transform: translate(-50%, 0) !important;
  transform: translate(-50%, 0) !important;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08) !important;
}
.notification-badge {
  position: absolute !important;
  top: -7px !important;
  left: 7px !important;
  background-color: #ff7e7c;
  color: white !important;
  border-radius: 50% !important;
  padding: 2px 4px !important;
  font-size: 0.55rem !important;
}

.userSidebarAvatar{
  width: 2.5rem; /* Adjust the size as needed */
  height: 2.5rem; /* Adjust the size as needed */
  border-radius: 50%;
  object-fit: cover; 
}
