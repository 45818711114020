.abstractCard{
    background-color: #374151;
    color: white;
    
}

.userAvatar{
    width: 8rem; /* Adjust the size as needed */
  height: 8rem; /* Adjust the size as needed */
  border-radius: 50%;
  object-fit: cover; 
    box-shadow: 0px 0px 1rem white;
    transition: opacity 0.3s ease;
    
}

.editAvatar{
    width: 8rem;
    position: absolute;
    top: 40%;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.avatar-container {
    position: relative;
    display: inline-block;
}

.avatar-container:hover .editAvatar {
    opacity: 1;
    z-index: 99;
}

.avatar-container:hover .userAvatar {
    opacity: 0.4; /* Adjust opacity as desired */
}

.nameLine{
    border: 1px solid #ff7e7c;
    width: 50%;   
 
}

.userDetailRow{
    background-color: white;
    padding: 1rem 1rem;
    border: 1px solid #cecece98;
    border-radius: 16px;
}

.userPasswordRow,.userNotificationRow{
    background-color: white;
    border: 1px solid #cecece98;
    border-radius: 16px;
}

.userPassword > input{
    padding-left: 5rem;
    border: 0.5px solid #cecece98 !important;
    min-width: 15vw;
    
}

.userPasswordIcon{
    position: relative;
    left: 3rem;
    color: #5B5B5B;
    z-index: 99;
}

.disableOptions{
    margin: 0;
    padding: 0.5rem 1rem;
}

.notificationsSelect > div{
    border: 0px !important;    
}

.notificationsSelect > .p-button.p-highlight:hover,.p-button.p-highlight{
    color: #4bb543 !important;
    background-color: white !important;
}

.green{
    color: green;
}

.red{
    color: rgb(255, 0, 0);
}

.editBtn{
    color: #ff7e7c;
    cursor: pointer;
    margin: 0 0.5rem;
}

.confirmBtn{
    background-color: #ff7e7c !important;
    border: none !important;
}

.confirmBtn:hover{
    background-color: #fc5553 !important;
}

.editDiv{
    position: relative;
    right: 5%;
}

.editBtn:hover{
    color: rgb(245, 107, 92);
    border-radius: 50%;
}

.checkFont{
    font-size: 1rem !important;
}

.disabled{
    color: gray;
    cursor: pointer;
    margin: 0 0.5rem;
}

.emailSaveBtn{
    padding: 0.5rem 1rem !important;
    min-width: 5rem !important
}

.pointer{
    cursor: pointer;
}

.disable{
    color: #d8d8d8 !important;
}

.moduleDialog-dropdown {
    background-color: #f9f9f9 !important;
    border: 1px solid #dbdbdb !important;
    max-width: 12vw;
    min-width: 12vw;
  
    /* color: black !important; */
  }