/* .tabDiv {
    background-color: white !important;
    border-radius: 24px !important;
    padding: 3px !important;
} */

.moduleTabList {
    background-color: white;
}


.moduleTab {
    font-size: 0.85rem !important;
    font-family: "Poppins", sans-serif !important;
    color: #5B5B5B;
    border: 1px solid transparent;
}

.alabtab {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0rem !important;
    border-top-right-radius: 0rem !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    border-top: 1px solid transparent !important;
    border-bottom: 1px solid transparent !important;
    margin-right: 0.1rem !important;
}

.moduleTab:not(:active) {
    border-top: 1px solid grey !important;
    border-bottom: 1px solid grey !important;
}


.mlabtab {
    border-bottom-right-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0rem !important;
    border-top-left-radius: 0rem !important;
    border-right: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-top: 1px solid transparent !important;
    border-bottom: 1px solid transparent !important;
}

.moduleTab.active {
    background-color: #FDE8E8 !important;
    border: 1px solid #ff7e7c !important;
    color: #ff7e7c !important;
    font-weight: bold !important;
    border-radius: 0.25rem !important;
}