* {
  box-sizing: border-box !important;
  font-family: "Lexend", sans-serif;
}
.minCardHeight {
  box-shadow: 0 0 10px rgb(0 0 35 / 5%) !important;
  background-color: #fff !important;
  border: none !important;
  border-radius: 0.5rem !important;
}
/* .withoutContent {
  min-height: 65vh !important;
} */
/* .dashboardRow {
} */


.color-contri-0 {
  background-color: #EBEDF0 !important;
}
.color-contri-1 {
  background-color: #C0DDF9 !important;
}
.color-contri-2 {
  background-color: #73B3F3 !important;
}
.color-contri-3 {
  background-color: #3886E1 !important;
}
.color-contri-4 {
  background-color: #17459E !important;
}
.react-calendar-heatmap .color-empty{
  fill:#EBEDF0;
  rx:10;
  width: 8px;
  height: 8px;
}
.react-calendar-heatmap .color-github-0 {
  fill: #eeeeee;
}
.react-calendar-heatmap .color-github-1 {
  fill: #C0DDF9;
  rx:10;
  width: 8px;
  height: 8px;
}
.react-calendar-heatmap .color-github-2 {
  fill: #73B3F3;
  rx:10;
  width: 8px;
  height: 8px;
}
.react-calendar-heatmap .color-github-3 {
  fill: #3886E1;
  rx:10;
  width: 8px;
  height: 8px;
}
.react-calendar-heatmap .color-github-4 {
  fill: #17459E;
  rx:10;
  width: 8px;
  height: 8px;
}
.react-calendar-heatmap text {
  font-size: 0.525rem !important;
  fill: #000;
  margin: 1rem;
}
.react-calendar-heatmap {
  /* viewbox:0 0 634 129; */
}
.react-calendar-heatmap-all-weeks{
  transform: translate(0, 25px);
}
.suiteNameMap {
  min-width: 25% !important;
  border-right: 1px solid #D1DAE7;
}
.suiteNameMap:last-child{
  border: 0;
}
.suiteNameMap:hover {
  transform: scale(1.1);
}
.suiteNameTable {
  max-width: 25% !important;
  word-break: break-all !important;
}
.react-calendar-heatmap .react-calendar-heatmap-small-text {
  font-size: 5px;
}

.react-calendar-heatmap rect:hover {
  stroke: #555;
  stroke-width: 1px;
}
.pi-external-link.tabIcon {
  cursor: pointer !important;
  font-size: 0.75rem !important;
}
.pi-external-link.tabIcon:hover {
  transform: scale(1.1);
}
.lastruns a {
  text-decoration: none !important;
  color: black !important;
  font-size: calc(0.8vw + 0.8vh);
}
.dashboardRow {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  /* padding: 0 8px; */
}

/* Create four equal columns that sits next to each other */
.column {
  -ms-flex: 33.33%; /* IE10 */
  flex: 33.33%;
  max-width: 33.33%;
  padding: 0 8px;
}

.stats-card-body {
  display: flex !important;
  flex-direction: column !important;
}

/* .stats-table {
  margin: auto 0 !important;
} */

.stats-table > div > table > tbody > tr {
  vertical-align: middle !important;
}
.column .card {
  margin-top: 16px;
  vertical-align: middle;
  width: 100%;
}
.statsCounter {
  /* animation: 1s ease-out 1s slideInFromLeft; */

  /* padding: 30px; */
}

.noPdataCard {
  min-height: 300px !important;
  min-width: 100% !important;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.heatmap-div {
  border: 1px solid rgb(179, 179, 179) !important;
  border-radius: 16px;
}
.white-skel {
  background-color: #e4eaee !important;
}

.contri-labels {
  display: flex;
  margin: auto 0 !important;
  color: #000000 !important;
  font-size: 0.8rem;
}
.contri-colors {
  display: flex;
  margin: auto 0 !important;
  height: 13px;
  width: 13px;
  border-radius: 50%;
}
.project-anaylysis-span{
  font-weight: 600;
  font-family: 'Lexend', sans-serif;
  font-size: 16px !important;
    letter-spacing: 1px;
}

.project-anaylysis-span-value{
  font-size: 26px !important;
  font-family: 'Lexend', sans-serif;

}
.project-analysis-div{
  padding: 0.5rem 1rem ;
}

.project-analysis-div:first-child{
  padding-left: 0;
}
.project-analysis-div:last-child{
  padding-right: 0;
}
.project-analysis{
  height: 110px !important;
  margin:0 !important;
  display: flex;
  padding: 0 15% !important; 
  flex-direction: column;
  justify-content: center;
  align-items: flex-start ;
  font-weight: 600;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
  border: 0 !important;
  border-radius: 12px !important;
  color: #fff;
}

.project-analysis-1{
  background: linear-gradient(90deg, #65B5EA 0%, #0F4C75 100%);
}
.project-analysis-2{
  background: linear-gradient(90deg, #2AF598 -19.17%, #009EFD 100%);
}
.project-analysis-3{
  background: linear-gradient(90deg, #F77062 0%, #FE5196 100%);
}
.project-analysis-4{
  background: linear-gradient(90deg, #F77062 0%, #EA3E3E 100%);
}

.running-suites-live{
  margin-bottom: 8px !important;
  font-size: 16px !important; 
  font-weight:500 !important; 
}

.running-suites-live-div{
  width: 40%
}

.dashboard-badge{
  background: #FF7E7C !important;
  font-size: 10px !important;
  min-width: 1.25rem !important;
  height: 1.25rem !important;
  line-height: 1.25rem !important;
}

.suite-data-div{
  height: 85%;
}
.card-title{
  font-size: 0.875rem !important;
  font-weight: 600;
  margin: 0 !important;
  color: #263238;
}
.p-tooltip-text{
  background: #1E1B39 !important;
  color: #fff !important;
  font-family: "Lexend", sans-serif !important;
  padding: 5px 10px !important;
  font-size: 10px !important;
}
.p-tooltip-arrow{
  border-bottom-color: #1E1B39 !important;
}
.suiteCount,.suiteName {
  font-size: 1rem !important;
  font-family: "Lexend", sans-serif !important;
}
.p-badge {
  background: #6366F1;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
}

.suiteName{
  font-size: 12px !important;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
  .project-analysis-div{
    padding: 10px 5px;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
  .suiteNameMap{
    width: 50% !important;
  }
  .project-analysis-div{
    padding: 4px 8px;
  }
  .project-analysis-div:nth-child(odd){
    padding-left: 0;
  }
  .project-analysis-div:nth-child(even){
    padding-right: 0;
  }
  .legend{
    flex-direction: column;
  }
  .project-analysis{
    height: 70px !important;
    padding: 10px !important;
  }
  .project-anaylysis-img{
    width: 20% !important;
    margin-right: 10px;
  }
  .project-anaylysis-span{
    font-size: 10px !important;
    font-weight: 600;
  }
  
  .project-anaylysis-span-value{
    font-size: 16px !important;
  }
}

@media only screen and (min-width: 1440px) {
  .heat-stats {
    margin-top: 2rem !important;
  }
}


