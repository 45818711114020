.customSignup {
  /* position: absolute !important; */
  /* left: 50% !important; */
  transform: scale(0.9);
  background: #ffffff !important;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25) !important;
  border-radius: 50px !important;
}
.crossicon {
  height: 50px;
}
.notValidUsername
{
  border: 1px solid red !important;
}
.acc {
  word-break: break-word !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-size: 0.8rem !important;
  color: #9d9d9d !important;
}
.titleSignup {
  font-family: "Poppins", sans-serif !important;

  font-weight: 600 !important;
  font-size: 1.4rem !important;

  color: #20245f;
}
.already {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-size: 12px !important;

  color: #9d9d9d !important;
}
#messagepass {
  font-size: 13px !important;
  margin-top: 5px !important;
  display: none;
}

#infopara {
  font-size: 10px !important;
  text-align: left;
}
.password-showicon {
  position: absolute;
  top: 12px;
  right: 7px;
  cursor: pointer !important;
}
.password-hideicon {
  position: absolute;
  top: 12px;
  right: 7px;
  cursor: pointer !important;
}

.inputfield {
  position: relative;
  outline: 0px !important;
}
.notAvail {
  font-size: 11px;
  color: red;
}
.avail {
  font-size: 11px;
  color: green;
}

.inputfield > img {
  position: absolute;
  z-index: 1 !important;
  top: 10px;
  left: 10px;
  height: 17px !important;
  width: 17px !important;
}

.infoUsername 
{
    position: absolute;
  z-index: 1 !important;
  top: 10px;
  left: auto !important;
  right: 0px !important;
  height: 17px !important;
  width: 17px !important;
}
.inputfield > input {
  padding-left: 55px;
  font-family: "Poppins", sans-serif !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  line-height: 1.7 !important;
}
.inputfield > input:focus {
  outline: none !important;
  box-shadow: 0 0 0 3px rgba(152, 202, 255, 0.8);
}
@media screen and (max-width: 992px) {
  .customSignup {
    margin-top: 25%;
    margin-bottom: 10%;
  }
}
@media screen and (max-width: 768px) {
  .customSignup {
    margin-top: 45% !important;
    margin-bottom: 10%;
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none !important;
}
#passField
{
  width: 100% !important;
}
#passField > .p-inputtext.p-component.p-password-input {
  display: block !important;
  width: inherit !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  line-height: 1.7 !important;
  color: #212529 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  appearance: none !important;
  border-radius: 0.25rem !important;
  margin: 0 !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
#passField.p-password input {
  padding-left: 55px !important;
  font-family: "Poppins", sans-serif !important;
}
#passField > .p-inputtext {
  padding: 0.375rem 0.75rem !important;
  padding-left: 55px !important;
}

#passField > .p-inputtext:enabled:focus {
  box-shadow: 0 0 0 3px rgb(152 202 255 / 80%) !important;
}
.p-password-panel {
  background-color: #fafcfd !important;
}

.signupTooltip.p-tooltip .p-tooltip-text {
  background-color: #273238 !important;
  font-size: 0.75rem !important;
  color: #fff !important;
}

.infoicon {
  filter: invert(68%) sepia(20%) saturate(880%) hue-rotate(314deg)
    brightness(96%) contrast(99%) !important;
}

.strength-meter {
  height: 10px;
  background-color: #eaeaea;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
  /* padding-top: 10px; */
}

.strength-meter-fill {
  height: 100%;
  transition: width 0.2s ease;
}

.strength-null {
  background-color: #D2122E;
  width : 25%
}

.strength-Medium {
  background-color: #ffc61a;
  width: 75%;
}

.strength-Strong {
  background-color: #36b336;
  width: 100%;
}
