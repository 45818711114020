.newTabs-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.newTabs-wrapper {
  display: flex;
  height: 3rem;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Hide vertical scrollbar */
  flex: 1;
  background-color: #e0e0e0;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.newTabs-wrapper::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.activeCol{
  color: #ff7e7c;
}

.newTab {
  display: flex;
  align-items: center;
  padding: 0 8px; /* Adjust padding as needed */
  border-right: 1px solid grey;
  border-left: 1px solid grey;
  background-color: #ffffffd0; /* Example background color */
  border-radius: 4px; /* Optional: Rounded corners */
  white-space: nowrap; /* Prevent text wrapping */
  cursor: pointer;
}

.newTabAdd {
  display: flex;
  background-color: #e0e0e0 !important;
  align-items: center;
  height: 3rem;
  color: black !important;
  padding: 0 8px; /* Adjust padding as needed */
  white-space: nowrap; /* Prevent text wrapping */
  border-radius: 0 !important;
}

.newTab.active {
  background-color: #ffffff; /* Example active background color */
}

.newTabIcon {
  margin-right: 8px;
}

.btn-left,.btn-right{
  height: 3rem;

}

.crossDiv {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.newTabTooltip {
  white-space: nowrap; /* Prevent text wrapping in the tooltip */
}

.newTabText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* Prevent text wrapping */
}

.newSliderBtn {
  z-index: 1;
}

.newSliderBtn#newSlideLeftLeft, .newSliderBtn#newSlideRightRight {
  position: absolute;
}

.newSliderBtn#newSlideLeftLeft {
  left: 0;
}

.newSliderBtn#newSlideRightRight {
  right: 0;
}

.newSliderBtn#newSlideLeft {
  position: absolute;
  left: 50px;
}

.newSliderBtn#newSlideRight {
  position: absolute;
  right: 50px;
}

.tabContent {
  padding: 16px; /* Adjust padding as needed */
  margin-top: 16px; /* Space between tabs and content */
}
