.work-minutes-hero-section-background {
  background: url("../../../../assets/testpadHeroSectionMobileBackgroundImage.png");
  background-position: center bottom !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.work-minutes-hero-section-heading {
  color: #333;
  font-size: 2rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 3rem */
  margin-bottom: 0.75rem !important;
}
.work-minutes-hero-section-description {
  color: #5b5b5b;
  font-size: 1.125rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.6875rem */
  margin-bottom: 2rem !important;
}

.work-minutes-hero-section-image {
  width: 100%;
}
@media (min-width: 1024px) {
  .work-minutes-hero-section-background {
    background: url("../../../../assets/testpadHeroSectionDesktopBackgroundImage.png");
  }
  .work-minutes-hero-section-heading {
    font-size: 2.5rem !important;
    text-align: center;
  }
  .work-minutes-hero-section-description {
    font-size: 1.5rem !important;
    margin-bottom: 2.5rem !important;
    text-align: center;
  }
  .work-minutes-hero-section-image {
    margin-top: 4.5rem;
  }
}
