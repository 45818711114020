.autoRefresh {
  min-width: max-content !important;
  font-size: 1rem !important;
  border: 1px solid transparent !important;
}
.autoRefresh:focus {
  box-shadow: none !important;
}
.p-togglebutton.p-button.p-highlight.autoRefresh {
  background-color: #ff7e7c !important;
  color: white !important;
}
.p-togglebutton.p-button.p-highlight.autoRefresh:hover {
  background-color: #ff7e7c !important;
  color: white !important;
}
.p-togglebutton.p-button.autoRefresh:hover {
  background-color: white !important;
  border: 1px solid #ff7e7c !important;
}
