.advantage-cards-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-top: 2rem;
}
@media (min-width: 1024px) {
  .advantage-cards-wrapper {
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    padding-top: 3rem;
    margin-top: 0rem;
  }
}
