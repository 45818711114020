.newTab {
  /* background-color: #ff7e7c !important;
  color: white !important; */
  /* background-color: white !important; */
  /* border: 1px solid #878787 !important; */
  color: #878787 !important;
  border-radius: unset !important;
}
/* .p-datatable .p-sortable-column .p-sortable-column-icon {
  font-size: calc(0.55vh + 0.55vw) !important;
} */

.tabClass {
  background-color: #f3f3f3 !important;
  color: #878787 !important;
  border-right: 1px solid #878787 !important;
  border-left: 1px solid #878787 !important;
  padding: 0.5rem 0.5rem !important;
  border-radius: unset !important;
}
.tabClass.active {
  background-color: white !important;
  border: white !important;
  padding: 0.5rem 0.5rem !important;
  border-right: 1px solid #878787 !important;
  border-left: 1px solid #878787 !important;
}
.tabItem .tabClass.active {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12) !important;
  transform-origin: 30 30 !important ;
  transform: scale(1.12, 1.1) !important;
  /* border: 2px outset transparent !important; */
  /* border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important; */
}
.tabClass.active .tabIcon {
  color: #ff7e7c !important;
}
.cross {
  border: 0.5px solid transparent !important;
  margin: 8px;
}
.cross:hover {
  border: 0.5px solid #878787 !important;
  margin: 8px;
}
.tabPane {
  position: relative !important;
  background-color: #f3f3f3 !important;
  /* max-width: 85vw !important; */
  padding: 5px;
  min-height: 6.5vh !important;
  max-height: 6.5vh !important;
  overflow-x: scroll;
  flex-wrap: nowrap !important;
  overflow-y: hidden;
}
.rightDiv {
  min-height: 6.5vh !important;
  max-height: 6.5vh !important;
}
.d-flexRow {
  /* max-height: 50px !important; */
  background-color: #f3f3f3 !important;
}

.tabItem {
  min-width: fit-content !important;
}
.sliderBtn {
  border-radius: unset !important;
  border: 1px solid white !important;
}
.sliderBtn:focus {
  box-shadow: none !important;
}

/* width */
.tabPane::-webkit-scrollbar {
  /* width: 90vw; */
  /* height: 0px; */
  display: none !important;
}
.tabTooltip.p-tooltip .p-tooltip-text {
  font-size: 0.65rem !important;
  background-color: #dedede !important;
  color: black;
  /* opacity: 0.15 !important; */
}
.tabTooltip.p-tooltip {
  margin-top: 1% !important;
}
.tabTooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: #ff7e7c !important;
}
/* Track */
.tabPane::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  display: none !important;
}

/* Handle */
.tabPane::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 0px;
  display: none !important;
}

/* Handle on hover */
.tabPane::-webkit-scrollbar-thumb:hover {
  background: #b30000;
  display: none !important;
}
.content {
  white-space: pre !important;
  word-wrap: normal !important;
}
