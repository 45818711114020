.ticketsCardSkel {
  border-radius: 10px !important;
  box-shadow: 0px 4px 12px 4px #f0f2ff !important;
  border: none !important;
}

.ticketsCard {
  border-radius: 10px !important;
  background-color: white !important;
  box-shadow: 0px 4px 12px 4px #f0f2ff !important;
  border: none !important;
}

.ticket-cardData {
  color: #6b6b6b !important;
}

.ticket-open
{
  cursor: pointer !important;
  color : #ff7e7c !important;
}
.ticket-key
{
    background-color: #D8EFF0 !important;
    color : #2684FF !important;
    min-width: 8vw !important;
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
 
}

.boldHeading {
    font-weight: 600 !important;
}