.p-button-outlined.selectFramework {
  color: #ff7e7c !important;
  border: 1px solid #ff7e7c !important;
  position: absolute;
  left: 0;
  top: 85% !important;
  bottom: 0;
  /* margin-top: 30% !important; */
}
input[type="file"].upload {
  display: none;
}
.p-button-outlined.backFramework {
  color: #ff7e7c !important;
  border: 1px solid #ff7e7c !important;
}
.p-button-outlined.selectFramework.disabled {
  cursor: not-allowed !important;
}
.inputHeight {
  min-height: 9vh !important;
  max-height: 9vh !important;
  border: 1px solid #ced4da !important;
}
.inputHeight .p-dropdown-label.p-inputtext {
  border: unset !important;
}
.chipHeight .p-inputtext.p-chips-multiple-container {
  overflow: auto !important;
  max-height: 9vh !important;
  border: 1px solid #ced4da !important;
  max-width: 20vw !important;
}
.dialogExecuteSuite {
  font-size: 1rem !important;
}
.loaderBoot {
  color: #ff7e7c !important;
}
