.highlights-wrapper {
  background: linear-gradient(
    180deg,
    rgba(246, 247, 252, 0.6) 0%,
    rgba(246, 247, 252, 0.6) 100%
  );
}
.highlights-text-and-image-wrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0rem;
}
.highlights-image {
  max-width: 100%;
}

.highlights-heading {
  color: #333;
  font-size: 1.5rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 2.25rem */
  margin-bottom: 0 !important;
  margin-top: 2rem;
}
.highlights-description {
  color: #727273;
  font-size: 1.125rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: 0.03375rem;
  margin-bottom: 0 !important;
  margin-top: 1rem;
}
@media (min-width: 1024px) {
  .highlights-text-and-image-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 3rem 0rem;
  }
  .highlights-image {
    flex: 1 1 0%;
    max-width: 47%;
  }
  .highlights-text-wrapper {
    flex: 1 1 0%;
    max-width: 47%;
  }
}
