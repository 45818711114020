.password-showicon {
  position: absolute;
  top: 12px;
  right: 7px;
  cursor: pointer !important;
}
.password-hideicon {
  position: absolute;
  top: 12px;
  right: 7px;
  cursor: pointer !important;
}
.wrapHead {
  word-wrap: break-word !important;
  max-width: 20rem !important;
}
#warningCredentials {
  /* display:none !important; */
  color: red !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none !important;
}

.passField > .p-inputtext.p-component.p-password-input {
  display: block !important;
  width: inherit !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  line-height: 1.7 !important;
  color: #212529 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  appearance: none !important;
  border-radius: 0.25rem !important;
  margin: 0 !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.passField.p-password input {
  padding-left: 55px !important;
  font-family: "Poppins", sans-serif !important;
}
.passField > .p-inputtext {
  padding: 0.375rem 0.75rem !important;
  padding-left: 55px !important;
}

.passField > .p-inputtext:enabled:focus {
  box-shadow: 0 0 0 3px rgb(152 202 255 / 80%) !important;
}
.p-password-panel {
  background-color: #fafcfd !important;
}

.strength-meter {
  height: 10px;
  background-color: #eaeaea;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
  /* padding-top: 10px; */
}

.strength-meter-fill {
  height: 100%;
  transition: width 0.2s ease;
}

.strength-null {
  background-color: #D2122E;
  width : 25%
}

.strength-Medium {
  background-color: #ffc61a;
  width: 75%;
}

.strength-Strong {
  background-color: #36b336;
  width: 100%;
}
