.custom-dialog {
  position: fixed;
  top: 0;
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 1100;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  border-radius: 6px;
}

.custom-dialog.show-dialog {
  opacity: 1;
  transform: translateY(0);
}

.dialog-header {
  background-color: #2D3948;
  padding: 10px;
  min-height: 9vh;
  position: relative;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
}

.header-content {
  color: white;
  /* margin-left: 3vw; */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* text-align: center; */
  flex-grow: 1;
}

.close-button {
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  position: absolute;
  /* top: 10px;
  right: 10px;  */
}

.dialog-content {
  padding: 20px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  flex-grow: 1;
  max-height: 82vh;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: normal;
}

.content-container {
  max-height: 100%;
}

.dialog-footer {
  border-radius: 0px 0px 0px 16px;
  background: rgba(255, 126, 124, 0.10);
  padding: 10px;
  min-height: 9vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: white;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.custom-dialog.left {
  left: 0;
}

.custom-dialog.center {
  left: 50%;
  transform: translateX(-50%);
}

.custom-dialog.right {
  right: 0;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 998;
}

.custom-dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}

.cancel-button {
  margin-right: 10px;
}

.submit-button {
  background-color: #ff7e7c;
  color: #fff;
}

@media (max-width: 767px) {
  .custom-dialog {
    width: 75% !important;
  }

  .dialog-footer {
    min-height: 5% !important;
    max-height: 40% !important;
  }

  .dialog-header {
    min-height: 5% !important;
  }

  @supports (-webkit-touch-callout: none) {
    .custom-dialog {
      height: 90vh !important;
    }
  }
}