.header {
  height: 3.5rem;
}
.header p {
  margin-bottom: 0rem !important;
}
.header a {
  text-decoration: none !important;
}
.header-jewel-icon {
  height: 100%;
  -webkit-animation: spin 12s linear infinite;
  -moz-animation: spin 12s linear infinite;
  animation: spin 12s linear infinite;
}
.header-shadow {
  background: #fff;
  box-shadow: none;
  border-bottom: 1px solid #d1dae7;
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
}
.padding {
  padding: 0.75rem 1.5rem;
}
.self-stretch {
  align-self: stretch;
}
.jewel-text-icon-wrapper {
  display: flex;
  height: 100%;
  align-items: center;
  gap: 0.25rem;
}
.jewel-header-text {
  margin-top: 0.25rem;
  color: #2c2c2c !important;
  text-align: center;
  font-size: 2rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.mobile-hidden-desktop-flex {
  display: none;
}
.desktop-hidden-mobile-flex {
  display: flex;
}
.header-links-wrapper {
  gap: 2rem;
  height: 100%;
  align-items: center;
}

@media (min-width: 1024px) {
  .header {
    height: 4rem;
  }
  .header-shadow {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    border-bottom: none;
  }
  .padding {
    padding: 0rem 4rem;
  }
  .header-jewel-icon-link {
    padding: 0.75rem 0rem;
  }
  .mobile-hidden-desktop-flex {
    display: flex;
  }
  .desktop-hidden-mobile-flex {
    display: none;
  }
}

.cursor-default {
  cursor: default;
}
.header-link-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}
.header-link {
  color: #333;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 1rem 0;
}
.header-link:hover {
  color: #f4736e;
}
/* This is the underline element of header links */
.header-link::after {
  content: "";
  height: 0.25rem;
  width: 0;
  border-radius: 0.125rem;
  background: #f4736e;
  position: absolute;
  left: 0;
  bottom: 0.75rem;
  transition: 0.3s ease-out;
  pointer-events: none;
}
.header-link-wrapper:hover > .header-link::after {
  width: 100%;
}
.header-link-active {
  color: #f4736e;
}
.header-link-active::after {
  content: "";
  height: 0.25rem;
  width: 100%;
  border-radius: 0.125rem;
  background: #f4736e;
  position: absolute;
  left: 0;
  bottom: 0.75rem;
  transition: 0.3s ease-out;
  pointer-events: none;
}

/* This is the menu onHover of header-link */
.header-link-menu-wrapper {
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 30 !important;
  padding: 3rem;
  margin: 0rem 5rem 0rem 0rem;
  border-radius: 0rem 0rem 1.25rem 1.25rem;
  background: #fff;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.08);
  opacity: 0;
  transition: 0.3s ease-out;
  width: max-content;
  pointer-events: none;
  display: flex;
  align-items: center;
  column-gap: 1rem;
}
.header-link-wrapper:hover > .header-link-menu-wrapper,
.header-link-menu-wrapper:hover {
  opacity: 1;
  pointer-events: all;
}

/* Menu elements  */
.header-link-menu-heading {
  color: #010101;
  font-size: 1.25rem !important;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.05rem;
}
.header-link-menu-description {
  color: #5b5b5b;
  font-size: 0.875rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
/* This is the underline element of menu description */
.header-link-menu-description::after {
  content: "";
  height: 0.13rem;
  width: 10%;
  border-radius: 0.125rem;
  background: #f4736e;
  position: absolute;
  bottom: -0.25rem;
  left: 0;
  pointer-events: none;
}
.header-link-menu-content-wrapper {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  column-gap: 2.5rem;
}
.header-link-menu-content-heading {
  color: #010101;
  font-size: 0.875rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.035rem;
}
.header-link-menu-content-link {
  color: #5b5b5b;
  font-size: 0.875rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 0.5rem;
}
.menu-options-heading-wrapper {
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}
.menu-options-heading-icon {
  width: 1.75rem;
  height: 1.75rem;
}
@media (min-width: 1024px) {
  .header-link-menu-content-link:hover {
    color: #f4736e;
  }
  .header-buttons-wrapper {
    padding: 0.75rem 0rem;
  }
}
.header-buttons-wrapper {
  gap: 1.5rem;
}

.relative {
  position: relative;
}
.w-fit {
  width: fit-content;
}
.primary-color {
  color: #f4736e;
}
