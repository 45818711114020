.otp-verification-card {
  font-family: "Poppins", sans-serif !important;
}
.otp-verification-card h1 {
  font-weight: 600 !important;
  font-size: 1.4rem !important;
  color: #20245f;
}
.otp-verification-card h2 {
  font-size: 0.875rem !important;
  margin-bottom: 0rem !important;
  line-height: 150%;
}
.otp-verification-card h3 {
  font-size: 0.75rem !important;
  font-weight: 600;
  margin-bottom: 0rem !important;
}
.otp-verification-card h4 {
  font-size: 1rem !important;
  font-weight: 600;
  color: #0d6efd;
  margin-top: 1rem;
  margin-bottom: 0rem !important;
  text-decoration: underline;
  cursor: pointer;
}
.otp-verification-card input {
  color: black !important;
  font-weight: 500;
  letter-spacing: 2px;
  background-color: #dee2e6 !important;
}
.otp-verification-card .p-button-label {
  font-size: 1rem !important;
  letter-spacing: 1px;
}
.otp-verification-card_outlined-button {
  color: black !important;
}
.otp-verification-card_outlined-button:hover {
  color: white !important;
}
