.standout-feature-card-wrapper {
  border-radius: 1.25rem;
  border: 1px solid #d1dae7;
  margin: 3rem 1rem;
  padding: 2.25rem 2.62rem;
  box-shadow: 10px 40px 50px 0px rgba(229, 233, 246, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
}

.standout-feature-card-heading {
  color: #333;
  text-align: center;
  font-size: 1.5rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem; /* 133.333% */
  margin-bottom: 0 !important;
  height: 4rem;
}
.standout-feature-image-wrapper {
  width: 90%;
  height: 9.875rem;
  display: flex;
  justify-content: center;
}
.standout-feature-card-image {
  object-fit: contain;
}
.standout-feature-card-description {
  color: #5b5b5b;
  text-align: center;

  /* Body2 - R */
  font-size: 1.125rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.6875rem */
  margin-bottom: 0 !important;
  height: 7rem;
}
@media (min-width: 767px) {
  .standout-feature-card-description {
    height: 6rem;
  }
}

