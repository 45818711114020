.reportPill {
    background-color: white;
    border: 1px solid #2C2C2C;
    border-radius: 0.8rem;
}

.activeReportPill {
    background-color: #2C2C2C;
    border: 1px solid transparent;
    border-radius: 0.8rem;
    color: white;
}

.shareReportImg {
    height: 6vh;
    width: 6vw;
}

.accessImg {
    height: 5vh;
    width: 5vw;
}

.shareReportHeading {
    font-size: 1.2rem !important;
    font-weight: bold !important;
}

.accessOption {
    font-size: 1.1rem !important;
    text-align: left !important;
    font-weight: bold;
}

.accessDesc {
    text-align: left !important;
}

.shareReportDesc {
    font-weight: normal !important;
}

.shareReportInput .p-inputtext {
    border: 1px solid #ced4da !important;
}

.selectAccess {
    display: grid !important;
}

.selectAccess>.p-button {
    margin-top: 1.5vh !important;
    margin-bottom: 1.5vh !important;
    border-radius: 6px !important;
    border: 1px solid #ced4da !important;
}

.radioIcon {
    height: 1vh;
    width: 1vw;
    border-radius: 50%;
    border: 1px solid black;
}

.reportCards {
    border-radius: 0.6rem !important;
    border: none !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.18);
    min-height: 32vh !important;
}

.reportHeading {
    font-size: 1rem !important;
    font-weight: 600;
}

.reportSubHeading {
    font-weight: 600;
}

.reportShareIcons {
    color: #ff7e7c !important;
    cursor: pointer;
    background-color: transparent !important;
}

.reportShareIcons:hover {
    transform: scale(1.2);
    border: none !important;
}

.disableState{
    opacity: 0.5;
}
