#tsparticles {
  position: fixed !important;
}
.dataHome {
  /* font-family: "poppins", sans-serif !important; */
  /* font-size: calc(0.7vh + 0.7vw) !important; */
  position: relative !important;
  z-index: 2 !important; 
  background-color: transparent !important;
  min-height: 92vh !important;
  display: flex;
  flex-direction: column;
}

.homeText {
  /* position: absolute !important; */
  width: 90%;
  border-radius: unset !important;

  border-top-right-radius: 3% !important;
  border-bottom-right-radius: 3% !important;
  border-top-left-radius: 3% !important;
  border-bottom-left-radius: 3% !important;
  border: none !important;
  border-right: 8px solid rgba(255, 126, 124, 0.7) !important;
  border-left: 8px solid rgba(255, 126, 124, 0.7) !important;

  background-color: rgba(247, 231, 233, 1) !important;
  /* background-color: transparent !important; */
  margin-top: auto !important;
  margin-bottom: auto !important;

  min-height: max-content !important;
}
.headerHomeText {
  font-size: calc(1.8vh + 1.8vw) !important;
  font-weight: 600 !important;

  color: #05174b !important;
}
.headerHome {
  font-size: 2.4rem !important;
  font-weight: 600 !important;

  color: #05174b !important;
}
.appCard {
  cursor: pointer !important;
  border-radius: 5% !important;
  max-height: 100% !important;
  min-height: -webkit-fill-available !important;
}
.appCard:hover {
  transform: scale(1.01);
}
.appCardContent {
  color: rgba(0, 0, 0, 0.8) !important  ;
}
a > .appCard {
  color: #05174b !important;
}
.cardIcon {
  color: rgba(255, 126, 124, 1);
  height: 2.5vh !important;
  width: 1.2vw !important;
  /* font-size: calc(1vh + 1vw) !important; */
}
.cardIconDiv {
  background-color: rgba(255, 126, 124, 0.2) !important;
  padding: 3.5% !important;
  border-radius: 50% !important;
  border: 1px solid #878787;
}
.cardName {
  font-weight: 600 !important;
}

.headerHomeContent {
  max-width: 70vw !important;
  color: rgba(0, 0, 0, 0.8) !important  ;
}
