.testpad-scroll-card-wrapper {
  margin-top: 2rem;
  display: grid;
}
.testpad-card-heading {
  color: #333;
  font-size: 1.25rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.5rem !important;
  width: fit-content;
}
.testpad-card-description {
  color: #5b5b5b;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
  margin-bottom: 0.5rem !important;
}
.testpad-card-link {
  color: #ff7e7c;
  font-size: 0.875rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  text-decoration: none !important;
  padding: 0.5rem 0.5rem 0.5rem 0rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: fit-content;
}
.testpad-card-link:hover {
  color: #ff7e7c !important;
}
.testpad-card-image {
  width: 100%;
  margin-top: 1rem;
}
@media (min-width: 1024px) {
  .testpad-card-heading {
    font-size: 1.5rem !important;
  }
}
