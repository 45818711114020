.overview-text-and-image-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2rem;
}

.overview-text {
  color: #5b5b5b;
  font-size: 1.25rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.6875rem */
  margin-bottom: 1.5rem;
}
.overview-image {
  width: 100%;
  margin-top:3rem;
}
@media (min-width: 1024px) {
  .overview-text-and-image-wrapper {
    grid-template-columns: 1fr 1fr;
    margin-top: 3rem;
    gap: 3rem;
    align-items: center;
    justify-items: end;
  }
  .overview-image {
    width: 80%;
   
  }
  .overview-text {
    font-size: 1.125rem !important;
    margin-bottom: 0rem !important;
  }
}
