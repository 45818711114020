.requirementPill {
  background-color: #f6f7fc !important;
  color: rgba(0, 0, 0, 0.6) !important;
  border-radius: unset !important;
  /* border-bottom: 3px solid #C6C2DE !important; */
}
.requirementPill.active {
  color: #000000 !important;
  border-bottom: 3px solid #ff7e7c !important;
  border-radius: unset !important;
}

.requirementList {
  border-bottom: 3px solid #c6c2de !important;
}

.propertiesCardSkel {
  border-radius: 10px !important;
  box-shadow: 0px 4px 12px 4px #F0F2FF !important;
  border: none !important;
}

.propertiesCard {
  border-radius: 10px !important;
  background-color: white !important;
  box-shadow: 0px 4px 12px 4px #F0F2FF !important;
  border: none !important;
}
.reqFormCard
{
    /* border-radius: 10px !important;
    background-color: white !important;
    box-shadow: 0px 4px 12px 4px #F0F2FF !important;
    border: none !important; */
    border: none !important;
}
.attach-cardData
{
    color: #6b6b6b !important;
}
.deleteReq
{
  cursor: pointer !important;
}