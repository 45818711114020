.hero-section-background {
  background-color: #f6f7fc;
}

.hero-section-image {
  width: 100%;
  margin-top: 2.5rem;
}
.hero-section-heading {
  color: #333;
  font-size: 2rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 3rem */
  margin-bottom: 0 !important;
}
.hero-section-description {
  margin-top: 0.75rem;
  color: #555;
  font-size: 1.125rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 2.25rem */
  margin-bottom: 0 !important;
}

.landing-page-hero-section-button-margin-top {
  margin-top: 2rem !important;
}

@media (min-width: 1024px) {
  .hero-section-wrapper {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .hero-section-image-wrapper {
    width: 75%;
  }
  .hero-section-heading {
    font-size: 2.5rem !important;
  }
  .hero-section-description {
    font-size: 1.5rem !important;
  }
  .landing-page-hero-section-button-margin-top {
    margin-top: 3rem !important;
  }
}
