.main-nav {
  background-color: #f6f7fc !important;
  color: #000000 !important ;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.navbar-light {
  background-color: transparent !important;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(5, 23, 75, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}
.navbar-light .navbar-toggler {
  border: solid 1px #05174b !important;
}
.main-nav a {
  color: #05174b !important;
  text-decoration: none !important;
}

.navbar-toggler-icon {
  color: black !important;
}

.main-nav .btn-header {
  width: 100px;
  height: 38px;
}
.main-nav .btn-header:hover {
  color: white !important;
}
.main-nav .btn-primary,
.main-nav .btn-primary:hover {
  background-color: #ff7e7c !important;
  border: solid 1px #ff7e7c !important;
}
.main-nav .btn-outline-primary {
  border: solid 1px #ff7e7c !important;
  color: #ff7e7c !important;
}
.main-nav .btn-outline-primary:hover {
  background-color: #ff7e7c !important;
  border: solid 1px #ff7e7c !important;
  font-weight: bold !important;
}

.btn-text-login {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
}

.btn-text-signup {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  color: white;
}
