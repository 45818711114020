.usage-autolytics-grid-wrapper {
  display: grid;
  gap: 2rem;
  margin-top: 2rem;
}
.usage-autolytics-card__icon {
  width: 4rem;
  height: 4rem;
}
.usage-autolytics-card__heading {
  color: #333;
  font-size: 1.25rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem !important;
}
.usage-autolytics-card__description {
  color: #5b5b5b;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
  margin-bottom: 0rem !important;
}

@media (min-width: 1024px) {
  .usage-autolytics-grid-wrapper {
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 3rem;
  }
  .usage-autolytics-card__icon {
    width: 5rem;
    height: 5rem;
  }
  .usage-autolytics-card__heading {
    font-size: 1.5rem !important;
    margin-bottom: 1rem !important;
  }
}
