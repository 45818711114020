.accessBtn > button {
  color: white !important;
  border: none !important;
  font-size: 0.8rem !important;
}

.requestAccept {
  font-family: "Poppins", sans-serif;
  color: white !important;
  font-size: 0.8rem !important;
  border-radius: 5px !important;
}

.requestDeny {
  font-family: "Poppins", sans-serif;
  color: white !important;
  font-size: 0.8rem !important;
  border-radius: 5px !important;
}

.acceptIcon,
.rejectIcon {
  cursor: pointer !important;
  color: black !important;
  font-size: 1rem !important;
}

.disabledReqIcon {
  font-size: 1rem !important;
  color: #9e9e9e !important;
  cursor: not-allowed !important;
}

.acceptIcon:hover {
  color: green !important;
}
.rejectIcon:hover {
  color: red !important;
}
