.recyclebin-section {
    height: 80vh !important;
    background-color: white !important;
    border-radius: 20px;
    box-shadow: 4px 4px 10px #00000024;
}

.subTabs.active {
    color: #ff7e7c !important;
    border-bottom: 2px solid #ff7e7c !important;
    background-color: transparent !important;
}

.recycleBinTable {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12) !important;
    border-radius: 20px !important;
}

.recycleBinTable>.p-datatable-wrapper {
    min-height: 55vh !important;
}

.recycleBinTable>.p-datatable-header {
    background-color: white !important;
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
}

.recycleBinTable>.p-datatable-wrapper {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.recycleBinTable>.p-datatable-wrapper>.p-datatable-table>.p-datatable-thead>tr>th {
    background-color: #2d3948 !important;
    color: white !important;
}

.recycleBinTable>.p-paginator {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.recycleBinTable .p-sortable-column .p-sortable-column-icon {
    color: white !important;
}

.recycleBinTable>.p-datatable-footer {
    background-color: white !important;
    border: none !important;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    padding: 0 !important;
}

.recycleBinTable>.p-datatable-footer>.customPaginator {
    padding: 0.5rem 0.5rem !important;
}