.p-button {
  box-shadow: none !important;
}
.base-button {
  border-radius: 0.375rem !important;
  padding: 0.75rem 1.5rem !important;
}
.base-button .p-button-label {
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: 0.05rem;
}
.primary-button {
  background: #f4736e !important;
  color: #fff !important;
  border: 1px solid #f4736e !important;
}
.secondary-button {
  background: #fff !important;
  color: #f4736e !important;
  border: 1px solid #f4736e !important;
}
.secondary-button-transition {
  transition: scale 2s;
}
.secondary-button-transition:hover {
  color: white !important;
  background-color: #f4736e !important ;
}
.secondary-button-transition:active {
  transform: scale(0.95);
}
.tertiary-button {
  background: #fff !important;
  color: #f4736e !important;
  border: 1px solid white !important;
}

.glow-button {
  transition: 0.3s ease-out !important;
}
.glow-button:hover {
  box-shadow: 0 0rem 0.5rem #f4736e !important;
}
.glow-button:active {
  box-shadow: none !important;
}

.component-heading {
  color: #333;
  text-align: center;
  font-size: 1.5rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: 3.125rem; /* 125% */
  margin-bottom: 0 !important;
}
.component-padding {
  padding: 3rem 1.5rem;
}
.mobile-block-desktop-hidden {
  display: block;
}
.mobile-hidden-desktop-block {
  display: none;
}
@media (min-width: 1024px) {
  .mobile-block-desktop-hidden {
    display: none;
  }
  .mobile-hidden-desktop-block {
    display: block;
  }

  .hover-right-arrow::after {
    content: "";
    display: inline-block;
    opacity: 0;
    width: 0;
    height: 0.85219rem;
    background-image: url("./assets/buttonRightArrowIcon.svg");
    background-size: contain;
    background-position-y: 50%;
    background-repeat: no-repeat;
    margin-left: 0.5rem;
    transition: 0.3s ease-out;
  }
  .hover-right-arrow:hover::after {
    width: 1.95119rem;
    opacity: 1;
  }
}
.max-w-1440 {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

/* carousel used in landing page */
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #d9d9d9 !important;
  width: 1rem !important;
  height: 1rem !important;
  border-radius: 50% !important;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #f4736e !important;
  width: 4rem !important;
  border-radius: 2rem !important;
}
.p-carousel-indicator .p-link:focus {
  box-shadow: none !important;
}

@media (min-width: 1024px) {
  .component-padding {
    padding: 5rem 4rem;
  }
  .component-heading {
    font-size: 2.5rem !important;
  }
}
