.timelineHeaderDiv {
    text-align: left !important;
    max-width: 100%;
    overflow: hidden;
}

.badgeStatusTimeline {
    display: inline-block !important;
    height: 8px !important;
    width: 8px !important;
    border-radius: 50% !important;
}

.timelineDialog {
    border: 1px solid #C6C2DE !important;
}

.tcKey {
    font-weight: bold;
}

.badgeStatusTimelineCol {
    display: -webkit-box !important;
    justify-content: start !important;
}

.tcValue {
    color: #615E83 !important;
}

@media (max-width: 768px) {
    .timelineHeaderDiv .d-flex {
        display: flex;
        flex-direction: column;
    }

    .timelineHeaderDiv .p-input-icon-left .p-inputtext {
        width: 100%;
    }
}
