.sidePanel{
    background-color: white !important;
    border-radius: 1.5rem;
    box-shadow: 0px 0px 8px rgb(211, 211, 211);
}
.sidePanelHeader{
    font-size: 1.4rem !important;
    font-weight: bold;
}

.sidePanelDescription{
    font-size: 1.2rem !important;
    font-weight: bold;
}

.sidePanelDescriptionText{
    font-size: 1rem !important;
    font-weight: 500 !important;
}

.sidePanelInstances{
    box-shadow: 0px 0px 8px rgb(211, 211, 211);
}

.code-container {
    position: relative;
    width: 100%;
   
}

.code-block {
    position: relative;
    background: #2d2d2d;
    color: #f8f8f2;
    padding: 2rem 1rem;
    border-radius: 5px;
    max-height: 20vh;
    overflow: auto;
    font-family: monospace;
    font-size: 14px;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin-bottom: 10px;
}

.code-block-dialog {
    position: relative;
    max-height: 50vh;
    background: #2d2d2d;
    color: #f8f8f2;
    padding: 2rem 1rem;
    border-radius: 5px;
    overflow: auto;
    font-family: monospace;
    font-size: 14px;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin-bottom: 10px;
}

.code-actions{

    position: absolute;
    top: 1rem;
    right: 1rem;
    
}

.pointer{
    cursor: pointer;
}

.closeSplitterIcon{
    position: sticky;
  top: 1rem; 
  margin-left: auto;
  z-index: 1000;
  cursor: pointer;
}

.instanceText{
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
}

.instanceText:hover{
    color: blue;
}

.codeText{
    word-break: break-all;
}

.instanceAccordion >a >.p-accordion-header-text{
    word-break: break-word;
}

.dastInstanceText{
    font-weight: 600 !important;
    font-size: 1rem !important;
}

.dastInstanceValue,.sastInstanceValue{
    font-weight: 600;
    color: grey;
}