.breadLink
{
    cursor: pointer !important;
    color:#4D4D4D !important;

}

.trends{
    font-size: 1.1rem !important;
}
.breadLink:hover{
    color:black !important;
    text-decoration: underline !important;;
}
.breadLinkDisabled {
        color:#4D4D4D !important;
font-weight: normal !important;
font-size: 0.9rem !important;
}