.footer {
  padding: 2%;
  background-color: #f6f7fc;
  bottom: 0;
  left: 0;
  width: 100%;
}

.heading-text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 53px;
  color: #023b59;
}
.sub-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.02em;

  color: #09090a !important;
}
.heading-text2 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #023b59;
}
.list {
  list-style-type: none;
  padding-left: 0%;
  cursor: pointer;
}
.list a {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-size: 14px !important;
  line-height: 21px;
}
.icon_links {
  margin: 10px 10px;
}
