.expandDataTable {
  max-width: 95% !important;
}
.maxWidthDataTable {
  max-width: 15vw !important;
  white-space: normal !important;
}
.maxWidthFullDataTable {
  max-width: 25vw !important;
  white-space: normal !important;
}

.reportHead {
  color: #023b59 !important;
}
.reportHead div {
  /* font-size: 1.7rem !important; */
}
.expandFullDataTable {
  max-width: 96% !important;
}
.reportIcons.teststep {
  color: #023b59 !important;
  opacity: 0.75;
  font-size: 1.1rem !important;
}
.testStep {
  min-width: 60vw !important;
}
.splitTestStep {
  min-width: 150% !important;
}
.xmark {
  position: fixed !important;
  z-index: 99;
  cursor: pointer;
  right: 2rem;
  top: 1rem;
  height: 3.5% !important;
  border: 1px solid black !important;
  border-radius: 20% !important;
  /* box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25); */
  /* border: 1px solid black !important; */
  /* border-radius: 25% !important;  */
}
.reportIcons.teststep:hover {
  border: 1px solid #023b59 !important;
}
.metaDataCards {
  width: 92% !important;
}
.tc_search.p-inputtext
{
  background-color: white !important;
  border-radius: 5px !important;
}
