.userInput{
    padding-left: 5rem !important;
    border: 0.5px solid #cecece98 !important;
    min-width: 25vw;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    
}

.disabledInput{
    cursor: not-allowed;
}

.userInputIcon{
    position: relative;
    left: 3rem;
    color: #5B5B5B;
}

.verify{
    color: #ff7e7c;
    font-size: 1rem !important;
    text-decoration: underline;
    margin: 1rem;
    cursor: pointer;
}

.verify:hover{
    color: rgb(248, 73, 54);
    text-decoration: underline;
    margin: 1rem;
}