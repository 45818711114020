.adminSection {
  font-family: "Poppins", sans-serif;
  font-style: normal;
}
.userIcon {
  font-size: 1.2rem;
  line-height: 20px;
  filter: invert(68%) sepia(20%) saturate(880%) hue-rotate(314deg)
    brightness(96%) contrast(99%) !important;
}

.infoicon {
  filter: invert(68%) sepia(20%) saturate(880%) hue-rotate(314deg)
    brightness(96%) contrast(99%) !important;
}

.p-badge {
  /* background-color: #EEF2FF !important; */
  font-size: 0.7rem !important;
}
/* .p-chips
{
  min-height: 7 !important;
  max-height: 7 !important;
} */
.p-virtualscroller-content .p-multiselect-item {
  height: 43px !important;
}
.p-multiselect-filter-container input {
  border: 1px solid #e3e6ea !important;
}
.project-details .p-datatable .p-datatable-header {
  font-size: 1rem !important;
  /* min-height: 4vh !important;
  max-height: 4vh !important; */
}
.changeRoleLink {
  font-size: 0.85rem !important;
  font-family: "Poppins", sans-serif !important;
}
.addUserLink {
  font-size: 0.85rem !important;
  font-family: "Poppins", sans-serif !important;
}
.changeRoleLink.active {
  background-color: white !important;
  border-bottom: 2px solid #ff7e7c !important;
  color: black !important;
}
.addUserLink.active {
  background-color: white !important;
  border-bottom: 2px solid #ff7e7c !important;
  color: black !important;
}
.pCheck {
  font-size: 0.7rem !important;
  color: green !important;
}
.pCheck-not {
  font-size: 0.7rem !important;
  color: red !important;
}
.p-chips-token {
  color: #ff7e7c !important;
}
.p-chips-token-label {
  font-size: 0.7rem !important;
}
.p-chips-token-icon {
  font-size: 0.7rem !important;
}
.AdminHeading {
  font-weight: 600;
  font-size: 1.3rem;
  text-align: left !important;
  line-height: 20px;
  color: #090a0a;
  border-bottom: 2px solid #ff7e7c;
}

.wrapperPillsAdmin
{
    background-color: white !important;
    border-radius: 24px !important;
    padding: 4px !important;
}

.requestPillAdmin
{
  background-color: white !important;
  color: black !important;
    border-radius: 24px !important;

}
.accessApprovalDialog > .p-dialog-content 
{
  min-height: 65vh !important;
  max-height: 65vh !important;
}

.requestPillAdmin.active
{
  background-color: #FF7E7C1A !important;
  color: #FF7E7C !important;
  border-radius: 24px !important;
}

